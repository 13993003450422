import { Suspense, useEffect } from 'react';
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { DarkThemeToggle, Spinner } from 'flowbite-react';
import { useSelector } from 'react-redux';
import { useAccount } from 'wagmi';
import { DashboardOutlet, HeroOutlet, SharedOutlet } from './RouteOutlets';
import Hero from '../Screens';
import SideBar from '../Screens/Sidebar';
import { sidebarConfigArr } from '../Utils/Constants';
import Welcome from '../Screens/Welcome';
import {
  commonRoutes,
  DOCS_PATH,
  EDIT_PATH,
  HELP_PATH,
  // ORG_CHALLENGE_LIST_PATH,
  ORG_PATH,
  privateRouteSources,
  publicRouteSources,
  SHARED_PATH,
} from '../Utils/Constants/RouteConstants';
import { RootState } from '../@types';
import { updateLocalStorage } from '../Utils/Helpers';
import ErrorBoundaryFallback from '../Components/ErrorBoundaryFallback';

const MainRouter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isConnected } = useAccount();
  const isOrgSelected = useSelector((state: RootState) => state.utils.isOrgSelected);
  const isGuest = useSelector((state: RootState) => state.utils.isGuest);
  const initialLoading = useSelector((state: RootState) => state.utils.initialLoading);
  // const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    /**Temp fix for shared routes */
    if (location.pathname.startsWith('/shared/challenge/')) {
      const parts = location.pathname.split('/');
      const challengeId = parts.pop();
      navigate(`challenge/${challengeId}`);
    }
  }, [location.pathname]);

  useEffect(() => {
    // if (isOrgSelected) { navigate(ORG_CHALLENGE_LIST_PATH) }
    if (isConnected) {
      const restPaths = [DOCS_PATH, HELP_PATH];
      if (location.pathname.trim().includes(EDIT_PATH)) {
        //So that if user refresh page on edit path
        navigate('/');
        return;
      }
      if (
        (isOrgSelected && location.pathname.startsWith(ORG_PATH)) ||
        restPaths.includes(location?.pathname)
      ) {
        navigate(location?.pathname);
      } else if (isOrgSelected) {
        navigate(location?.pathname);
      }
    } else if (!location.pathname.includes(SHARED_PATH)) {
      //   navigate("/");
    }
    window.addEventListener('beforeunload', updateLocalStorage);
  }, []);

  const showSidebar =
    !sidebarConfigArr.includes(location.pathname) && !location.pathname.includes(SHARED_PATH);
  return (
    <>
      {/* <Header /> */}
      {/* Side bar has to be rendered in both condition because it manages connect wallet component */}
      {initialLoading ? (
        <div className='text-center flex h-full items-center justify-center dark:bg-primary-900 test mr-32'>
          <SideBar className={initialLoading ? 'hidden' : ''} />
          <Spinner size='xl' className='self-center' />
        </div>
      ) : (
        <div className='flex grow tracking-wide'>
          {showSidebar ? (
            <SideBar className={initialLoading ? 'hidden' : ''} />
          ) : (
            <div className='fixed bottom-5 right-10 rounded-lg shadow shadow-black dark:shadow-gray-600 z-50'>
              <DarkThemeToggle className='focus:!ring-0' />
            </div>
          )}
          <div className='dark:bg-primary-900 grow w-min'>
            <Suspense
              fallback={
                <div className='text-center flex h-full items-center justify-center'>
                  <Spinner size='xl' className='self-center' />
                </div>
              }
            >
              <Routes>
                <Route path='' element={<HeroOutlet />}>
                  <Route path='' element={<Hero />} />
                  {!isConnected &&
                    isGuest &&
                    publicRouteSources.map(({ path, component: Component }) => (
                      <Route key={path} path={path} element={<Component />} />
                    ))}
                  {/* <Route path="signup" element={<Welcome />} /> */}
                </Route>
                <Route path='' element={<Outlet />}>
                  <Route path='' element={<SharedOutlet />}>
                    {/* {sharedRoutes.map(({ path, component: Component }) => (
										<Route key={path} path={path} element={<Component />} />
									))} */}
                  </Route>

                  <Route path='' element={<DashboardOutlet />}>
                    {/* <Route path="signup" element={<Welcome />} /> */}
                    {publicRouteSources.map(({ path, component: Component }) => (
                      <Route key={path} path={path} element={<Component />} />
                    ))}
                    {privateRouteSources.map(({ path, component: Component }) => (
                      <Route key={path} path={path} element={<Component />} />
                    ))}
                    <Route path='signup' element={<Welcome />} />
                  </Route>
                  {commonRoutes.map(({ path, component: Component }) => (
                    <Route key={path} path={path} element={<Component />} />
                  ))}
                </Route>
                <Route path='*' element={<ErrorBoundaryFallback />} />
                {/* Fallback route */}
              </Routes>
            </Suspense>
          </div>
        </div>
      )}
    </>
  );
};
export default MainRouter;
