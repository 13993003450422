import { HiOutlineClipboardDocumentList, HiOutlineBuildingOffice } from 'react-icons/hi2';
import { MdOutlineHelpOutline, MdOutlineLogout } from 'react-icons/md';
import { CgOrganisation } from 'react-icons/cg';
import { sideNavItem } from '../../@types';
import { IoMdTrophy } from 'react-icons/io';
import { MdOutlineSettings } from 'react-icons/md';
// import { FaMedal } from 'react-icons/fa';

import {
  ALL_ORG_LIST,
  DOCS_PATH,
  GUEST_CHALLENGE_LIST_PATH,
  HELP_PATH,
  ORG_CHALLENGE_LIST_PATH,
  USER_PREFERENCES_PATH,
  USER_CHALLENGE_LIST_PATH,
  USER_ORG_LIST_PATH,
  USER_ORG_PROFILE_LIST_PATH,
  USER_SUBMISSIONS_LIST_PATH,
  Leaderboard_Path,
  // Leaderboard_Path,
} from './RouteConstants';
import { FaMedal } from 'react-icons/fa';

export const orgSidebarOptions: sideNavItem[] = [
  {
    path: ORG_CHALLENGE_LIST_PATH,
    name: 'Challenges',
    icon: IoMdTrophy,
  },
];

/* login profile sidebar */
export const userSidebarOptions: sideNavItem[] = [
  {
    path: USER_PREFERENCES_PATH,
    name: 'My Preferences',
    icon: MdOutlineSettings,
  },
  {
    path: USER_ORG_PROFILE_LIST_PATH,
    name: 'My Organizations',
    icon: HiOutlineBuildingOffice,
  },
  {
    path: USER_SUBMISSIONS_LIST_PATH,
    name: 'My Submissions',
    icon: MdOutlineLogout,
  },
];
export const userNavBarOrgLists = [
  {
    path: USER_CHALLENGE_LIST_PATH,
    name: 'Challenges',
    icon: IoMdTrophy,
  },
  {
    path: USER_ORG_LIST_PATH,
    name: 'Organizations',
    icon: CgOrganisation,
  },
  {
    path: Leaderboard_Path,
    name: 'Leaderboard',
    icon: FaMedal,
  },
];
export const guestNavBarOrgLists = [
  {
    path: GUEST_CHALLENGE_LIST_PATH,
    name: 'Challenges',
    icon: IoMdTrophy,
  },
  {
    path: ALL_ORG_LIST,
    name: 'Organizations',
    icon: CgOrganisation,
  },
  {
    path: Leaderboard_Path,
    name: 'Leaderboard',
    icon: FaMedal,
  },
];
/* sidebar footer list */
export const navBarFooterLists = [
  {
    path: DOCS_PATH,
    name: 'Docs',
    icon: HiOutlineClipboardDocumentList,
    subPages: [],
  },
  {
    path: HELP_PATH,
    name: 'Help',
    icon: MdOutlineHelpOutline,
  },
];

/**Warning test */

export const WALLET_CONNECT_WARNING_TEXT =
  'This functionality is best experienced on Desktop browser';

export const WALLET_BUTTON_WARNING_TEXT = 'Continue Exploring Desights';
