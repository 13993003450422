import { createSlice } from '@reduxjs/toolkit';
import { uniq } from 'lodash';

const organizationInitialState = {
  organizationList: [],
  loadingOrgs: [],
};
let initialState = organizationInitialState;
// Localstorage persistense - Not tested well.
const presistedState = localStorage.getItem('persistedstate');
if (presistedState) {
  initialState = JSON.parse(presistedState)?.organization ?? organizationInitialState;
}

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    organizationReset: () => organizationInitialState,
    addOrganization: (state, { payload }) => {
      state.organizationList.push(payload);
    },
    updateOrganization: (state, { payload }) => {
      state.loadingOrgs = state.loadingOrgs.filter((orgId) => orgId !== payload.profileId);
      const inx = state.organizationList.findIndex((data) => data?.profileId === payload.profileId);
      if (inx === -1) {
        state.organizationList.push(payload);
      } else {
        state.organizationList[inx] = { ...state.organizationList[inx], ...payload };
      }
    },
    updateOrganizationList: (state, { payload }) => {
      if (payload.length) {
        state.organizationList = payload;
      }
    },
    updateOrganizationAdminsList: (state, { payload }) => {
      if (payload.allAdmins.length) {
        const inx = state.organizationList.findIndex(
          (data) => data.profileId === payload.orgProfileId,
        );
        state.organizationList[inx].admins = payload.allAdmins;
      }
    },
    updateOrganizationChallengeList: (state, { payload }) => {
      if (payload.challenges.length) {
        const inx = state.organizationList.findIndex(
          (data) => data.profileId === payload.orgProfileId,
        );
        state.organizationList[inx].challenges = payload.challenges;
      }
    },
    addAdmin: (state, { payload }) => {
      const inx = state.organizationList.findIndex(
        (data) => data.profileId === payload.orgProfileId,
      );
      state.organizationList[inx].admins.push(payload.userProfileId);
    },
    removeAdmin: (state, { payload }) => {
      const inx = state.organizationList.findIndex(
        (data) => data.profileId === payload.orgProfileId,
      );
      state.organizationList[inx].admins = state.organizationList[inx].admins.filter(
        (id: string) => id !== payload.userProfileId,
      );
    },
    addChallengeToOrg: (state, { payload }) => {
      const inx = state.organizationList.findIndex((data) => data.profileId === payload.profileId);
      state.organizationList[inx].challenges.push(payload.challengeId);
      state.organizationList[inx].totalRewards += parseInt(payload.rewardAmount);
    },
    addSubmissionToOrg: (state, { payload }) => {
      const inx = state.organizationList.findIndex((data) => data.profileId === payload.orgId);
      state.organizationList[inx].submissions.push(payload.proposalId);
    },
    increaseSolvedChallengeCount: (state, { payload }) => {
      const inx = state.organizationList.findIndex((data) => data.profileId === payload.orgId);
      state.organizationList[inx].solvedChallenges.push(payload.challengeId);
    },
    decreaseSolvedChallengeCount: (state, { payload }) => {
      const inx = state.organizationList.findIndex((data) => data.profileId === payload.orgId);
      state.organizationList[inx].solvedChallenges = state.organizationList[
        inx
      ].solvedChallenges.filter((id: string) => id !== payload.challengeId);
    },
    updateLoadingOrgs: (state, { payload }) => {
      state.loadingOrgs = uniq([...state.loadingOrgs, ...payload]);
    },
    removeLoadingOrg: (state, { payload }) => {
      state.loadingOrgs = state.loadingOrgs.filter((orgId) => orgId !== payload);
    },
  },
});

export default organizationSlice.reducer;
export const {
  updateOrganizationList,
  organizationReset,
  addChallengeToOrg,
  addOrganization,
  updateOrganization,
  removeAdmin,
  addAdmin,
  addSubmissionToOrg,
  increaseSolvedChallengeCount,
  decreaseSolvedChallengeCount,
  updateOrganizationAdminsList,
  updateOrganizationChallengeList,
  updateLoadingOrgs,
  removeLoadingOrg,
} = organizationSlice.actions;
