import ReactDOMClient from 'react-dom/client';
import { Flowbite } from 'flowbite-react';
import App from './App';
import './index.css';
import { CustomFlowbiteTheme } from 'flowbite-react';
const customTheme: CustomFlowbiteTheme = {
  sidebar: {
    inner:
      'h-full overflow-y-auto overflow-x-hidden rounded bg-white px-3 py-4 dark:bg-primary-800',
    item: {
      base: 'flex items-center justify-center rounded-lg p-2 text-base font-normal text-gray-900 hover:bg-gray-100 dark:text-headingColor font-weight-800 dark:hover:bg-primary-700',
      active: 'dark:!text-primary-700',
      content: {
        base: 'flex-1 whitespace-nowrap px-3',
      },
      icon: {
        base: 'h-6 w-6 flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-headingColor dark:group-hover:text-headingColor',
        active: 'text-gray-700 dark:!text-primary-700',
      },
    },
  },
};
try {
  const container = document.getElementById('root');
  if (!container) throw new Error('No root element was found in the document.');
  ReactDOMClient.createRoot(container).render(
    // <React.StrictMode>
    <Flowbite theme={{ theme: customTheme }}>
      <App />
    </Flowbite>,
    // </React.StrictMode>
  );
} catch (error) {
  console.error(error);
}
