import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { SHARED_PATH } from '../Utils/Constants';
import { RootState } from '../@types';

export interface RoutingManagerProps {
  HeroOutlet: JSX.Element;
  SignUpOutlet: JSX.Element;
  DashboardOutlet: JSX.Element;
  SharedOutlet: JSX.Element;
}

const RoutingManager = ({
  HeroOutlet,
  SignUpOutlet,
  DashboardOutlet,
  SharedOutlet,
}: RoutingManagerProps) => {
  // const [walletInvited, setWalletInvited] = useState(false);
  const { isConnected } = useAccount();
  const location = useLocation();
  const walletConnected = isConnected || isConnected === undefined;
  const isGuest = useSelector((state: RootState) => state.utils.isGuest);
  if (location.pathname.includes(SHARED_PATH)) return SharedOutlet;
  if (!walletConnected && isGuest) return HeroOutlet;
  else if (walletConnected && isGuest) return SignUpOutlet;
  else if (walletConnected && !isGuest) return DashboardOutlet;
  return HeroOutlet;
};

export default RoutingManager;
