import { AppDispatch, Profile } from '../../@types';
import {
  updateProfileDetails,
  updateCurrentProfile,
  updateDefaultProfile,
} from '../Slices/ProfileSlice';
import {
  resetSidebarSyncDetails,
  updateGuestState,
  updateOrgSelected,
  updateSelectedProfile,
  updateSidebarSyncDetails,
} from '../Slices/UtilsSlice';

// new
export const updateGuestStateAction = (data: boolean) => async (dispatch: AppDispatch) => {
  dispatch(updateGuestState(data));
};
export const updateOrgSelectedAction = (data: boolean) => async (dispatch: AppDispatch) => {
  dispatch(updateOrgSelected(data));
};
export const updateSelectedProfileAction =
  (data: { profileId: string; isOrg: boolean }) => async (dispatch: AppDispatch) => {
    dispatch(updateSelectedProfile(data));
  };
export const updateProfileDetailsAction = (data: Profile) => async (dispatch: AppDispatch) => {
  dispatch(updateProfileDetails(data));
};
export const updateCurrentProfileAction = (data: Profile) => async (dispatch: AppDispatch) => {
  dispatch(updateCurrentProfile(data));
};
export const updateDefaultProfileAction = (data: Profile) => async (dispatch: AppDispatch) => {
  dispatch(updateDefaultProfile(data));
};
export const updateSidebarSyncDetailsAction =
  (data: { name?: string; image?: string }) => async (dispatch: AppDispatch) => {
    dispatch(updateSidebarSyncDetails(data));
  };
export const resetSidebarSyncDetailsAction = () => async (dispatch: AppDispatch) => {
  dispatch(resetSidebarSyncDetails());
};
