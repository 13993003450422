import React from 'react';

interface LoadingDots {
  text: string;
  textclass?: string;
  className?: string;
}

const LoadingDots = ({ text, textclass, className }: LoadingDots) => {
  return (
    <div className={`loading-dots-container ${className}`}>
      <div
        className={`loading-text text-sm leading-[10px] text-gray-500 dark:text-gray-400 ${textclass ? textclass : ''}`}
      >
        {text}
      </div>
      <div className={`loading-dots ${textclass ? textclass : ''}`}>
        <div className='dot dot1 bg-gray-500 dark:bg-gray-400'></div>
        <div className='dot dot2 bg-gray-500 dark:bg-gray-400'></div>
        <div className='dot dot3 bg-gray-500 dark:bg-gray-400'></div>
      </div>
    </div>
  );
};

export default LoadingDots;
