import { Sidebar } from 'flowbite-react';
import { NavLink } from 'react-router-dom';
import { SideBarItem, SubMenu, renderSidebarListInterface } from '../../@types';
import { Tooltip } from 'flowbite-react';

export const RenderSidebarList = ({
  renderSidebar,
  setSidebarOpen,
  isCollapsed,
}: renderSidebarListInterface) => {
  return (
    <div>
      {renderSidebar.map((item: SideBarItem, index) => (
        <div key={index}>
          {!item.subPages?.length ? (
            <NavLink
              to={item.path}
              className={({ isActive }) =>
                `flex ${
                  isActive
                    ? 'bg-primary-50 dark:bg-primary-600'
                    : 'hover:bg-primary-50 hover:dark:bg-primary-600'
                } mt-1 rounded-lg`
              }
              onClick={() => setSidebarOpen(false)}
            >
              <Tooltip
                content={item.name}
                placement='right'
                className={isCollapsed ? '' : 'hidden'}
              >
                <Sidebar.Item
                  as='div'
                  icon={item.icon}
                  className={`w-full ${
                    isCollapsed ? 'p-3 !justify-center' : 'py-3'
                  } hover:bg-primary-50 hover:dark:bg-primary-600`}
                >
                  <p className={`text-sm ${isCollapsed ? 'hidden' : ''}`}>{item.name}</p>
                </Sidebar.Item>
              </Tooltip>
            </NavLink>
          ) : (
            !isCollapsed && (
              <Sidebar.Collapse className='!text-sm' label={item.name} icon={item.icon}>
                {item.subPages.map((subItem: SubMenu, subIndex) => (
                  <NavLink
                    key={subIndex}
                    to={subItem.subPath}
                    className={({ isActive }) =>
                      `flex ${
                        isActive
                          ? 'bg-primary-50 dark:bg-primary-600'
                          : 'hover:bg-primary-50 hover:dark:bg-primary-600'
                      } mt-1 rounded-lg`
                    }
                    onClick={() => setSidebarOpen(false)}
                  >
                    <Sidebar.Item
                      as='div'
                      icon={subItem.subIcon}
                      className='hover:bg-primary-50 hover:dark:bg-primary-600'
                    >
                      <div className='flex items-center text-sm'>{subItem.subName}</div>
                    </Sidebar.Item>
                  </NavLink>
                ))}
              </Sidebar.Collapse>
            )
          )}
        </div>
      ))}
    </div>
  );
};
