export const ORGANISATION_TABS = [
  {
    title: 'Profile',
    key: 0,
  },
  {
    title: 'Admins',
    key: 1,
  },
];

export const USER_PROFILE_TABS = [
  {
    title: 'Profile',
    key: 0,
  },
  {
    title: 'Admins',
    key: 1,
  },
];

export const CHALLENGES_TABS = [
  {
    title: 'Active',
    key: 0,
  },
  {
    title: 'In-Review',
    key: 1,
  },
  {
    title: 'Completed',
    key: 2,
  },
];

export const CHALLENGE_TABS_FOR_HOST = [
  {
    title: 'Active',
    key: 0,
  },
  {
    title: 'In-Review',
    key: 1,
  },
  {
    title: 'Completed',
    key: 2,
  },
  {
    title: 'Drafts',
    key: 3,
  },
];

export const OVERVIEW_TABS = [
  {
    title: 'Live',
    key: 0,
  },
  {
    title: 'Past Deadline',
    key: 1,
  },
  {
    title: 'Archived',
    key: 2,
  },
];
export const ADMIN_LIST_TABS = [
  {
    title: 'Active',
    key: 0,
  },
  {
    title: 'Pending',
    key: 1,
  },
];
export const PROFILE_TABS = [
  {
    title: 'Submissions',
    key: 0,
  },
  {
    title: 'Achievements',
    key: 1,
  },
];
