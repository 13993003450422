import { IoWarningOutline } from 'react-icons/io5';
import CommonModal from '../CommonModal';
import CommonButton from '../CommonButton';

interface WarningModalProps {
  show: boolean;
  warningText: string | number;
  onButtonClick;
  buttonText: string;
}

const CommonWarningModal = ({
  show,
  warningText,
  onButtonClick,
  buttonText,
}: WarningModalProps) => {
  return (
    <>
      <CommonModal showModal={show} size={'sm'}>
        <div className='text-center'>
          <IoWarningOutline className='text-yellow-500 text-5xl mx-auto' />
          <div className='text-gray-500 dark:text-gray-400 p-4 my-4'>{warningText}</div>
          <div className=''>
            <CommonButton
              onClick={onButtonClick}
              buttonClassName='my-auto mx-auto'
              buttonText={buttonText}
            />
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default CommonWarningModal;
