import { Toast } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { HiCheckCircle, HiOutlineDuplicate } from 'react-icons/hi';
import { StatDescriptionProps } from '../@types';
import { BiLinkExternal } from 'react-icons/bi';

const StatDescription = ({
  Icon,
  text,
  responsive = false,
  showCopy = false,
  copyText = '',
  showLink = false,
  linkAddress = '',
}: StatDescriptionProps) => {
  const [enableToast, setEnableToast] = useState(false);
  useEffect(() => {
    if (enableToast) {
      setTimeout(() => {
        setEnableToast(false);
      }, 3000);
    }
  }, [enableToast]);

  const onCopyClick = () => {
    navigator.clipboard.writeText(copyText);
    setEnableToast(true);
  };
  return text ? (
    <div className='flex justify-center sm:justify-start text-gray-500 dark:text-white gap-1 '>
      {Icon && (
        <Icon
          className={`${responsive ? 'text-sm md:text-lg' : 'text-lg'} font-light self-center`}
        />
      )}
      <p className={`${responsive ? 'text-xs md:text-sm' : 'text-sm'} font-[550] self-center`}>
        {text}
      </p>
      {showCopy && (
        <HiOutlineDuplicate
          onClick={onCopyClick}
          className='self-center cursor-pointer text-lg text-gray-400 hover:text-primary-500 dark:hover:text-primary-500 dark:text-gray-500'
        />
      )}
      {showLink && (
        <BiLinkExternal
          onClick={() => window.open(linkAddress, '_blank')}
          className='self-center cursor-pointer text-lg text-gray-400 hover:text-primary-500 dark:hover:text-primary-500 dark:text-gray-500'
        />
      )}
      {enableToast && (
        <Toast
          className='justify-between gap-2 py-2 px-3 !bg-primary-50  dark:!bg-gray-700 fixed bottom-7 left-0 right-0 mx-auto z-50'
          duration={75}
        >
          <div className='flex gap-2'>
            <HiCheckCircle className='text-primary-500 dark:text-primary-500 text-xl' />
            <div className='text-sm font-normal dark:text-white'>Text Copied</div>
          </div>
          <IoClose
            onClick={() => setEnableToast(false)}
            className='text-xl dark:text-white dark:hover:text-primary-500 hover:text-primary-500 cursor-pointer'
          />
        </Toast>
      )}
    </div>
  ) : (
    <></>
  );
};

export default StatDescription;
