import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { RootState } from '../@types';
import {
  HERO_PATH,
  SIGNUP_PATH,
  USER_SUBMISSIONS_LIST_PATH,
  USER_PROFILE_DETAILS_PATH,
  ORG_DETAILS_PATH,
} from '../Utils/Constants';
import RoutingManager from './RoutingManager';
// import Hero from '../Screens';

// This component will work as private route wrapper (router v6)
export const DashboardOutlet = () => {
  const location = useLocation();
  const isGuest = useSelector((state: RootState) => state.utils.isGuest);
  // console.log('THIS IS NUMBER 1',location.pathname.includes('edit'),isGuest)
  if (isGuest && location.pathname.includes('edit')) {
    return <Navigate to={'/'} />;
  }
  return (
    <RoutingManager
      HeroOutlet={<Outlet />}
      SignUpOutlet={<Outlet />}
      DashboardOutlet={<Outlet />}
      SharedOutlet={<Navigate to={location.pathname} />}
    />
  );
};

export const SignUpOutlet = () => {
  const location = useLocation();
  const isGuest = useSelector((state: RootState) => state.utils.isGuest);
  return (
    <RoutingManager
      HeroOutlet={<Navigate to={HERO_PATH} />}
      SignUpOutlet={isGuest ? <Outlet /> : <Navigate to={USER_SUBMISSIONS_LIST_PATH} />}
      DashboardOutlet={<Navigate to={USER_SUBMISSIONS_LIST_PATH} />}
      SharedOutlet={<Navigate to={location.pathname} />}
    />
  );
};

export const HeroOutlet = () => {
  const location = useLocation();
  const userProfileId = useSelector((state: RootState) => state?.profile?.currentProfile.profileId);
  const id = useSelector((state: RootState) => state?.profile?.currentProfile?.id);
  const isOrgSelected = useSelector((state: RootState) => state?.utils?.isOrgSelected);
  return (
    <RoutingManager
      HeroOutlet={<Outlet />}
      SignUpOutlet={<Outlet />}
      DashboardOutlet={
        <Navigate
          to={
            (isOrgSelected ? ORG_DETAILS_PATH : USER_PROFILE_DETAILS_PATH) +
            '/' +
            (isOrgSelected ? id : userProfileId)
          }
        /> //IF org is selected already then go to org profile else to user profile
      }
      SharedOutlet={<Navigate to={location.pathname} />}
    />
  );
};

export const SharedOutlet = () => {
  return (
    <RoutingManager
      HeroOutlet={<Navigate to={HERO_PATH} />}
      SignUpOutlet={<Navigate to={SIGNUP_PATH} />}
      DashboardOutlet={<Navigate to={USER_SUBMISSIONS_LIST_PATH} />}
      SharedOutlet={<Outlet />}
    />
  );
};
