import { AppDispatch } from '../../@types';

import {
  decreaseSolvedChallengeCount,
  increaseSolvedChallengeCount,
} from '../Slices/OrganizationSlice';

// export const updateChallengeAction =
//   (data: Challenge, navigate: NavigateFunction, dirtyFields: string[]) =>
//   async (dispatch: AppDispatch) => {
//     const { challengeId, profileId } = data;
//     const { IPFSChallengeData, Web3ChallengeData } = getAPIMappedChallenge(data, dirtyFields);
//   };

// export const markChallengeReviewCompleteAction =
//   (data: Challenge) => async (dispatch: AppDispatch) => {
//     try {
//       const { challengeId, profileId } = data;
//       const { IPFSChallengeData, Web3ChallengeData } = getAPIMappedChallenge(data, null);
//       // await updateOnChainChallenge(challengeId, profileId, IPFSChallengeData, Web3ChallengeData, () => { })
//       // dispatch(markChallengeCompletion(data))
//     } catch (error) {
//       console.error('Error to mark challenge as complete');
//     }
//   };

export const markChallengeResolvedAction =
  (data: { challengeId?: number; orgId?: string }) => async (dispatch: AppDispatch) => {
    // dispatch(markChallengeCompletion({ ...data, challengeResolved: true }))
    dispatch(increaseSolvedChallengeCount(data));
  };

export const markChallengeUnResolvedAction =
  (data: { challengeId?: number; orgId?: string }) => async (dispatch: AppDispatch) => {
    // dispatch(markChallengeCompletion({ ...data, challengeResolved: false }))
    dispatch(decreaseSolvedChallengeCount(data));
  };
