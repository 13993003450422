import { ethers } from 'ethers';
import { NavigateFunction } from 'react-router-dom';
import TokenList from '@uniswap/default-token-list';
import store from '../../Store';
import { ADDRESS_NOT_VALID, GUEST_PROFILE_CREATE_PATH } from '../Constants';
import { fetchuserProfileId, isUserProfileRegistered, isWalletInvited } from './APIHelpers';

export * from './WallectConfigHelper';
export * from './LocalstorageHelper';
export * from './FormHelper';
export * from './APIHelpers';

export const updateLocalStorage = () => {
  //setting initial loadings to false so if user closes tab on loading , it doesnt stuck at initial loading.
  localStorage.setItem(
    'persistedstate',
    JSON.stringify({
      ...store.getState(),
      utils: {
        ...store?.getState()?.utils,
        initialLoading: false,
        showTxnModal: false,
      },
    }),
  );
};

export const formatAddress = (str?: string) => {
  if (str === undefined) return '';
  return (str?.slice(0, 4) + '...' + str?.slice(str.length - 4, str.length)).toLowerCase();
};

export const removeImageMD = (mrkDwn: string) => {
  if (mrkDwn?.length) return mrkDwn?.replace(/(!\[.*\]\(.*\))/g, '[image hidden]\n');
  return mrkDwn;
};

export const getRandom = () => {
  // Check the account existence based on any API
  return Math.floor(Math.random() * 1000000000000000000).toString();
};

export const getTokenInfo = (tokenAddress) => {
  /**
   * TODO:Uncomment this, as static 137 is passed
   */
  // return TokenList?.tokens.find((tokenInfo) => (tokenInfo?.extensions?.bridgeInfo[envOpts.chainId]?.tokenAddress)?.toLowerCase() === tokenAddress.toLowerCase())
  return TokenList?.tokens.find(
    (tokenInfo) =>
      tokenInfo?.extensions?.bridgeInfo[137]?.tokenAddress?.toLowerCase() ===
      tokenAddress.toLowerCase(),
  );
};

export const getRewardText = (rewardAmount: string = '', rewardToken: string = '') => {
  if (!rewardAmount && !rewardToken) {
    return '';
  }
  const identifiedToken = getTokenInfo(rewardToken);

  if (identifiedToken) {
    return rewardAmount + ' ' + (identifiedToken.symbol || formatAddress(rewardToken));
  }
  return '';
};

// Temporary helpers
export const onCreateAccountClick = async (userAddress: string, navigate: NavigateFunction) => {
  const userProfileId = await fetchuserProfileId(userAddress);
  const userInvited = await isWalletInvited(userAddress, process.env.REACT_APP_REGISTRY);
  const isValidAddress = ethers.isAddress(userProfileId);
  if (isValidAddress) {
    const isRegistered = await isUserProfileRegistered(userProfileId);
    if (!isRegistered) {
      if (userInvited) {
        navigate(GUEST_PROFILE_CREATE_PATH);
      } else {
        navigate(ADDRESS_NOT_VALID);
      }
    }
  } else if (userInvited) {
    navigate(GUEST_PROFILE_CREATE_PATH);
  } else {
    navigate(ADDRESS_NOT_VALID);
  }
  return 0;
};
