import { NavigateFunction } from 'react-router-dom';
import { AppDispatch, Profile } from '../../@types';
// import { ADD_ADMIN_TXNS, ORG_PROFILE_DETAILS_PATH } from '../../Utils/Constants';
import {
  addAdminToOrg,
  addAdminToUser,
  createOnChainOrgProfile,
  fetchAllAdmins,
  fetchAllOrgs,
  fetchOrgProfileData,
  removeAdminFromOrg,
  updateOnChainOrgProfile,
} from '../../Utils/Helpers';
import { removeLoadingOrg } from '../Slices/OrganizationSlice';
import { updateOrgSelected } from '../Slices/UtilsSlice';

export const createOrgProfileAction =
  (data: Profile, userProfileId: string, navigate: NavigateFunction) => async () => {
    await createOnChainOrgProfile(data, userProfileId, navigate); // Create organization profile API call.
    // STILL HAVE TO IMPLEMENT LOGIC FOR REDIRECT AFTER GETTING ORG ID
    // if (profileId) {
    // dispatch(addOrganization({ ...data, profileId }));
    // dispatch(addOrgToMember({ userProfileId, orgProfileId: profileId }));
    // dispatch(addOrgToProfile(profileId));
    // dispatch(updateCurrentProfile({ ...data, profileId }))
    // dispatch(updateOrgSelected(true));
    // navigate(ORG_PROFILE_DETAILS_PATH + "/" + profileId);
    // dispatch(resetSidebarSyncDetailsAction())
    // }
  };

export const updateOrgProfileAction = (data: Profile, navigate: NavigateFunction) => async () => {
  // const complete = () => {
  //   dispatch(updateCurrentProfile(data));
  //   navigate(ORG_PROFILE_DETAILS_PATH + '/' + data.profileId);
  //   dispatch(resetSidebarSyncDetailsAction());
  // };
  updateOnChainOrgProfile(data, navigate); // Update user profile API call.
};

/**THIS IS FOR ADDING ADMIN TO THE ORG */
export const addAdminAction =
  (userProfileId: string, orgProfileId: string, onComplete: () => void) => async () => {
    addAdminToOrg(orgProfileId, userProfileId, onComplete);
  };

/**THIS IS FOR ADDING ADMIN TO THE USER */
export const addAdmintoUserAction =
  (
    address: string,
    adminToAdd: string,
    userProfileAddress: string,
    label: string,
    onComplete: () => void,
  ) =>
  async () => {
    addAdminToUser(address, adminToAdd, userProfileAddress, label, onComplete);
  };

export const removeAdminAction =
  (
    payload: { orgProfileId: string; userProfileId: string; defaultProfileId: string },
    onComplete: () => void,
  ) =>
  async (dispatch: AppDispatch) => {
    const complete = () => {
      if (payload.defaultProfileId === payload.userProfileId) {
        dispatch(updateOrgSelected(false));
      }
      onComplete();
    };
    // complete()
    removeAdminFromOrg(payload.orgProfileId, payload.userProfileId, complete);
  };

export const fetchOrgProfileAction = (orgProfileId: string) => async (dispatch: AppDispatch) => {
  try {
    await fetchOrgProfileData(orgProfileId);
    // dispatch(updateOrganization(orgProfileData));
  } catch (error) {
    dispatch(removeLoadingOrg(orgProfileId));
    console.error('Org. Fetch: ', orgProfileId);
    console.error(error);
  }
};

export const fetchMultipleOrgsAction = (orgIds: string[]) => async (dispatch: AppDispatch) => {
  for (let inx = 0; inx < orgIds?.length; inx++) {
    const orgProfileId = orgIds[inx];
    try {
      await fetchOrgProfileData(orgProfileId);
      // dispatch(updateOrganization(orgProfileData));
    } catch (error) {
      dispatch(removeLoadingOrg(orgProfileId));
      console.error('Multi Org. Fetch: ', orgProfileId);
      console.error(error);
    }
  }
};

export const fetchAllOrgsAction = () => async (dispatch: AppDispatch) => {
  try {
    const allOrgIds = await fetchAllOrgs();
    if (allOrgIds.length) {
      dispatch(fetchMultipleOrgsAction(allOrgIds));
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchAllAdminsAction = (orgProfileId: string) => async () => {
  const allAdmins = await fetchAllAdmins(orgProfileId);
  console.log(allAdmins);
  // dispatch(updateOrganizationAdminsList({ allAdmins, orgProfileId }));
};
