import { useEffect, useState } from 'react';
import { BsStars } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../@types';
import CommonButton from '../../Components/CommonButton';
import { updateGuestStateAction } from '../../Store/Actions';
import { ADDRESS_NOT_VALID, GUEST_CHALLENGE_LIST_PATH } from '../../Utils/Constants';

const Welcome = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onSkipClick = () => {
    navigate(GUEST_CHALLENGE_LIST_PATH);
    dispatch(updateGuestStateAction(true));
  };

  const handleCreateAccount = async () => {
    /**Commenting next lines as invitation is removed.
     * Just remove comment to get to previous flow of app.
     */
    // dispatch(profileReset());
    // setLoading(true)
    // await onCreateAccountClick(address as string, navigate)
    // setLoading(false)

    navigate(ADDRESS_NOT_VALID);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className='h-full flex justify-center dark:text-white'>
      <div className='max-w-md self-center'>
        <div className='sm:text-4xl text-2xl mb-5 sm:mb-10 font-black text-center'>
          Welcome to Desights
        </div>
        <div className=' flex justify-center px-3'>
          <div className='w-full'>
            <div className=' lg:flex items-center mb-6'>
              <div className=' text-3xl lg:text-6xl text-green-500 pr-2'>
                <BsStars />
              </div>
              <div>
                <div className=' text-xl lg:text-2xl font-extrabold pb-2 '>
                  Let’s get you started
                </div>
                <div className='text-sm sm:text-base text-gray-500'>
                  You will create your on-chain account that only you can control
                </div>
              </div>
            </div>
            <div className='flex flex-col gap-2 justify-center'>
              <CommonButton
                buttonText='Create My Account'
                buttonClassName='w-full'
                onClick={handleCreateAccount}
                showLoader={loading}
              />
              <CommonButton
                buttonText='I will do it later'
                buttonClassName='w-full !bg-transparent dark:!bg-transparent dark:!text-white dark:hover:!text-white hover:!text-white !text-primary-700 hover:bg-primary-100 dark:hover:bg-primary-100 border-2 border-primary-700'
                onClick={onSkipClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
