import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface Particle {
  id: number;
  x: number;
  y: number;
  color: string;
  angle: number;
  scale: number;
  delay: number;
}

interface Ribbon {
  id: number;
  angle: number;
  color: string;
  delay: number;
  radius: number;
}

interface LevelUpModalProps {
  payload: {
    newLevel: number;
  };
  onClose: () => void;
}

const LevelUpModal: React.FC<LevelUpModalProps> = ({ payload, onClose }) => {
  const [particles, setParticles] = useState<Particle[]>([]);
  const [ribbons, setRibbons] = useState<Ribbon[]>([]);

  useEffect(() => {
    // Updated celebration colors to match green theme
    const colors = ['#22c55e', '#16a34a', '#15803d', '#86efac', '#4ade80'];
    const numCircles = 3;
    const particlesPerCircle = 16;
    const newParticles: Particle[] = [];

    // Main circular particles
    for (let ring = 0; ring < numCircles; ring++) {
      const radius = (ring + 1) * 100;
      for (let i = 0; i < particlesPerCircle; i++) {
        const angle = (i * 2 * Math.PI) / particlesPerCircle;
        const startX = Math.cos(angle) * radius;
        const startY = Math.sin(angle) * radius;

        newParticles.push({
          id: ring * particlesPerCircle + i,
          x: startX,
          y: startY,
          color: colors[Math.floor(Math.random() * colors.length)],
          angle: angle,
          scale: 2 - ring * 0.2,
          delay: ring * 0.08 + (i / particlesPerCircle) * 0.1,
        });
      }
    }

    // Additional scattered particles
    for (let i = 0; i < 20; i++) {
      const angle = Math.random() * Math.PI * 2;
      const radius = Math.random() * 400 + 100;
      newParticles.push({
        id: numCircles * particlesPerCircle + i,
        x: Math.cos(angle) * radius,
        y: Math.sin(angle) * radius,
        color: colors[Math.floor(Math.random() * colors.length)],
        angle: angle,
        scale: Math.random() * 1.5 + 0.5,
        delay: Math.random() * 0.2,
      });
    }

    setParticles(newParticles);

    // Updated ribbon colors to green theme
    const ribbonColors = ['#22c55e', '#16a34a', '#15803d', '#4ade80'];
    const numRibbons = 16;
    const newRibbons: Ribbon[] = [];

    for (let i = 0; i < numRibbons; i++) {
      const angle = (i * 2 * Math.PI) / numRibbons;
      newRibbons.push({
        id: i,
        angle: angle,
        color: ribbonColors[i % ribbonColors.length],
        delay: i * 0.03,
        radius: 400 + Math.random() * 100,
      });
    }
    setRibbons(newRibbons);
  }, []);

  return (
    <AnimatePresence>
      <div className='fixed inset-0 bg-gray-900/90 dark:bg-gray-900/90 flex justify-center items-center z-50'>
        {/* Ribbons */}
        {ribbons.map((ribbon) => (
          <motion.div
            key={`ribbon-${ribbon.id}`}
            initial={{
              scale: 0,
              opacity: 0,
              x: 0,
              y: 0,
              rotate: (ribbon.angle * 180) / Math.PI,
            }}
            animate={{
              scale: 1,
              opacity: [1, 0],
              x: Math.cos(ribbon.angle) * ribbon.radius,
              y: Math.sin(ribbon.angle) * ribbon.radius,
            }}
            transition={{
              duration: 1.2,
              delay: ribbon.delay,
              ease: 'easeOut',
            }}
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              width: '30px',
              height: '6px',
              background: `linear-gradient(90deg, ${ribbon.color} 0%, transparent 100%)`,
              transformOrigin: 'center',
              borderRadius: '3px',
              filter: 'blur(1px)',
            }}
          />
        ))}

        {/* Particles */}
        {particles.map((particle) => (
          <motion.div
            key={particle.id}
            initial={{
              scale: 0,
              x: 0,
              y: 0,
              opacity: 1,
              rotate: (particle.angle * 180) / Math.PI,
            }}
            animate={{
              scale: particle.scale,
              x: particle.x,
              y: particle.y,
              opacity: 0,
            }}
            transition={{
              duration: 1.5,
              delay: particle.delay,
              ease: 'easeOut',
            }}
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              width: '20px',
              height: '20px',
              backgroundColor: particle.color,
              borderRadius: '50%',
              filter: 'blur(3px)',
              transform: `rotate(${particle.angle}rad)`,
              boxShadow: `0 0 8px ${particle.color}`,
            }}
          />
        ))}

        {/* Modal Content */}
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            type: 'spring',
            duration: 0.5,
            bounce: 0.4,
          }}
          className='bg-gradient-to-br from-white to-gray-100 dark:from-gray-900 dark:to-gray-800 
            p-8 rounded-2xl shadow-2xl text-center max-w-[400px] w-[90%] relative overflow-hidden 
            border dark:border-green-500/20 border-green-300/40'
        >
          <motion.div className='flex flex-col items-center gap-4'>
            <motion.div
              initial={{ scale: 0, rotate: -45 }}
              animate={{
                scale: [0, 1.2, 1],
                rotate: [-45, 0],
                y: ['0%', '-25%', '0%'],
              }}
              transition={{
                duration: 0.8,
                times: [0, 0.6, 1],
                ease: 'easeOut',
                repeat: Infinity,
              }}
              whileInView={{
                y: ['0%', '-10%', '0%'],
                rotate: [-5, 5, -5],
              }}
              viewport={{ once: false }}
              className='text-5xl mb-4'
            >
              🎉
            </motion.div>

            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className='text-gray-800 dark:text-white text-3xl font-bold uppercase tracking-wider m-0'
            >
              Level Up!
            </motion.h2>

            <motion.div
              initial={{ scale: 0, rotate: -180 }}
              animate={{ scale: 1, rotate: 0 }}
              transition={{
                type: 'spring',
                duration: 0.8,
                bounce: 0.5,
              }}
              className='relative w-40 h-40 flex items-center justify-center my-4'
            >
              {/* Crown */}
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  type: 'spring',
                  duration: 0.6,
                  delay: 0.4,
                }}
                className='absolute inset-0'
              >
                <svg viewBox='0 0 100 100' className='w-full h-full'>
                  {/* Crown base */}
                  <motion.path
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{
                      duration: 0.8,
                      delay: 0.6,
                      ease: 'easeInOut',
                    }}
                    d='M20 60 L10 30 L30 45 L50 20 L70 45 L90 30 L80 60 L20 60'
                    fill='none'
                    stroke='#22c55e'
                    strokeWidth='4'
                    className='drop-shadow-[0_2px_8px_rgba(34,197,94,0.5)]'
                  />
                  {/* Crown fill */}
                  <motion.path
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      duration: 0.3,
                      delay: 1.4, // Increased delay to wait for outline
                    }}
                    d='M20 60 L10 30 L30 45 L50 20 L70 45 L90 30 L80 60 L20 60'
                    fill='url(#crownGradient)'
                  />
                  <defs>
                    <linearGradient id='crownGradient' x1='0%' y1='0%' x2='0%' y2='100%'>
                      <stop offset='0%' style={{ stopColor: '#22c55e' }} />
                      <stop offset='100%' style={{ stopColor: '#15803d' }} />
                    </linearGradient>
                  </defs>
                </svg>
              </motion.div>

              {/* Jewels */}
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  delay: 1.7,
                  type: 'spring',
                  stiffness: 200,
                }}
                className='absolute top-10 left-0 right-0 flex justify-center space-x-8' // Adjusted spacing
              ></motion.div>

              {/* Level number */}
              <motion.div
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{
                  delay: 1.8,
                  type: 'spring',
                  stiffness: 100,
                }}
                className='relative z-10' // Adjusted margin
              >
                <span
                  className='text-gray-800 text-3xl font-bold'
                  style={{
                    textShadow: '0 0 10px rgba(34, 197, 94, 0.5)',
                  }}
                >
                  {payload.newLevel}
                </span>
              </motion.div>
            </motion.div>

            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className='text-gray-700 dark:text-white my-3'
            >
              Congratulations!
              <br /> You've reached Level {payload.newLevel}
            </motion.p>

            <motion.button
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={onClose}
              className='bg-green-500 hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-700 
                text-white px-6 py-2 rounded-md flex items-center gap-2 z-50 font-medium 
                shadow-lg shadow-green-500/20 dark:shadow-green-700/20'
            >
              Continue
            </motion.button>
          </motion.div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default LevelUpModal;
