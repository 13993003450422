import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(advanced);
dayjs.extend(relativeTime);

const DISPLAY_DATE_FORMAT = 'D MMM YYYY H:mm:ss';

export const getTimeStamp = (dateString: string) => dayjs(dateString).unix();

export const getToday = () => dayjs().format(DISPLAY_DATE_FORMAT);

export const getNow = () => dayjs().unix();

export const formatTimestamp = (timestamp: number) =>
  isNaN(timestamp) ? getToday() : dayjs.utc(timestamp * 1000).format(DISPLAY_DATE_FORMAT);
//export const formatTimestamp = (timestamp: number) => isNaN(timestamp) ? getToday() : dayjs.unix(timestamp).format(DISPLAY_DATE_FORMAT);

export const getTimeLeft = (timestamp: number) => {
  if (isNaN(timestamp)) return '';
  if (dayjs.unix(timestamp).isBefore(dayjs())) return 'expired on ' + formatTimestamp(timestamp);
  //const timeDiff = dayjs.duration(dayjs.unix(timestamp).diff(dayjs()).valueOf());
  //return timeDiff.humanize() + " left";
  return formatTimestamp(timestamp) + ' UTC';
};
