import { TbError404 } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../@types';
import CommonButton from '../Components/CommonButton';
import {
  ORG_CHALLENGE_LIST_PATH,
  SIGNUP_PATH,
  USER_SUBMISSIONS_LIST_PATH,
} from '../Utils/Constants';

const ErrorBoundaryFallback = () => {
  const navigate = useNavigate();
  const isOrgSelected = useSelector((state: RootState) => state.utils.isOrgSelected);
  const isGuest = useSelector((state: RootState) => state.utils.isGuest);

  const navigateToMainPage = () => {
    let path = USER_SUBMISSIONS_LIST_PATH;
    if (isOrgSelected) path = ORG_CHALLENGE_LIST_PATH;
    else if (isGuest) path = SIGNUP_PATH;
    navigate(path);
    location.reload();
  };

  return (
    <div className='py-8 px-4 mx-auto flex flex-col h-full items-center justify-center'>
      <div className='flex justify-center'>
        <TbError404 className='text-gray-500 text-8xl' />
      </div>
      <div className='my-2 text-base text-center font-medium text-gray-500'>
        Something went wrong.
      </div>
      <CommonButton buttonText={'Back main dashboard'} onClick={navigateToMainPage} />
    </div>
  );
};

export default ErrorBoundaryFallback;
