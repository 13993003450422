import { createClient } from '@supabase/supabase-js';
import { GUEST_CHALLENGE_LIST_PATH } from '../Constants';
export const getClient = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY,
);

export const signInWithGoogle = async () => {
  const supabase = getClient;
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: 'google',
    options: {
      redirectTo: `${window.location.origin}${GUEST_CHALLENGE_LIST_PATH}`,
      queryParams: {
        prompt: 'select_account', // Forces account selection
        access_type: 'offline', // Requests refresh token
      },
    },
  });
  console.log(data, error);
};

//   const { data, error } = await supabase.auth.signInWithOtp({
//     email,
//     options: {
//       shouldCreateUser: false,
//       emailRedirectTo: 'http://localhost:3000/challenge/list',
//     },
//   });
//   return { data, error };
// };
export const signUpWithEmail = async (email: string) => {
  const supabase = getClient;

  const { data, error } = await supabase.auth.signInWithOtp({
    email,
    options: {
      shouldCreateUser: false,
      emailRedirectTo: 'http://localhost:3000/challenge/list',
    },
  });
  if (error) {
    console.log(error);
  }
  return { data, error };
};
export const signInWithGithub = async () => {
  const supabase = getClient;
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: 'github',
    options: {
      redirectTo: `${window.location.origin}${GUEST_CHALLENGE_LIST_PATH}`,
      queryParams: {
        prompt: 'select_account', // Forces account selection
        access_type: 'offline', // Requests refresh token
      },
    },
  });
  return { data, error };
};
export const signInWithLinkedIn = async () => {
  const supabase = getClient;
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: 'linkedin_oidc',
    options: {
      redirectTo: `${window.location.origin}${GUEST_CHALLENGE_LIST_PATH}`,
    },
  });
  return { data, error };
};

export const signInWithDiscord = async () => {
  const supabase = getClient;
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: 'discord',
    options: {
      redirectTo: `${window.location.origin}${GUEST_CHALLENGE_LIST_PATH}`,
    },
  });
  if (error) {
    console.log(error);
  }
  return { data, error };
};
