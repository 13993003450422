// Transaction types
export const IPFS_UPLOAD = 'IPFS_UPLOAD';
export const WEB3_TRANSACTION = 'WEB3_TRANSACTION';
export const DB_UPLOAD = 'DB_UPLOAD';

// Steps status
export const STEP_NOT_STARTED = 'STEP_NOT_STARTED';
export const STEP_COMPLETED = 'STEP_COMPLETED';
export const STEP_PENDING = 'STEP_PENDING';
export const STEP_INCOMPLETE = 'STEP_INCOMPLETE';

// This will be the key constant which will be used to get the step info from the config object below.
// Each different scenarios like, create-user, update-user, etc., will have different constant variable.
export const CREATE_USER_PROFILE_TXNS = 'Creating Your Profile';
export const CREATE_ORG_PROFILE_TXNS = 'Creating Your Org Profile';
export const UPDATE_USER_PROFILE_TXNS = 'Updating Your Profile';
export const UPDATE_ORG_PROFILE_TXNS = 'Updating Your Org Profile';
export const ADD_ADMIN_TXNS = 'Adding New Admin';
export const ADD_ADMIN_TO_USER_TXNS = 'Adding New Admin To Profile';
export const REMOVE_ADMIN_TXNS = 'Removing Selected Admin';
export const REMOVE_ADMIN_FROM_USER_TXNS = 'Removing Selected Admin For Profile';
export const CREATE_CHALLENGE_TXNS = 'Creating New Challenge';
export const UPDATE_CHALLENGE_TXNS = 'Updating Selected Challenge';
export const CREATE_SUBMISSION_TXNS = 'Submitting Your Proposal';
export const CREATE_REVIEW_WITH_COMMENT = 'Create review with comment';
export const CREATE_REVIEW_WITHOUT_COMMENT = 'Create review without comment';
export const GET_DOWNLOAD_URL = 'Getting Download URL'; //to fetch download url using did of ocean
export const DATASET_DOWNLOADING = 'Downloading Dataset';
export const CLAIM_CERTIFICATE_TXNS = 'Claiming Certificate';

// Transaction step configuration object
export const stepConfig = {
  [CREATE_USER_PROFILE_TXNS]: [
    {
      stepId: 1,
      status: STEP_NOT_STARTED,
      text: 'Deploying Your Profile',
      type: WEB3_TRANSACTION,
    }, //step 1. deploy profile proxy
    {
      stepId: 2,
      status: STEP_NOT_STARTED,
      text: 'Deploying Manager to Manage Your Profile',
      type: WEB3_TRANSACTION,
    }, //step 2. deploy profile manager proxy
    {
      stepId: 3,
      status: STEP_NOT_STARTED,
      text: 'Transferring Your Profile Ownership to Manager',
      type: WEB3_TRANSACTION,
    }, //step 3. transfer ownership of profile to profile manager
    {
      stepId: 4,
      status: STEP_NOT_STARTED,
      text: 'Uploading Your Profile data to IPFS',
      type: IPFS_UPLOAD,
    }, //step 4. upload data to IPFS
    {
      stepId: 5,
      status: STEP_NOT_STARTED,
      text: 'Making You Super Admin of Your Profile',
      type: WEB3_TRANSACTION,
    }, //step 5. set default permissions + profile data on profile
    {
      stepId: 6,
      status: STEP_NOT_STARTED,
      text: 'Claiming Profile Ownership using Manager',
      type: WEB3_TRANSACTION,
    }, // step 6. claim profile ownership using profile manager
    {
      stepId: 7,
      status: STEP_NOT_STARTED,
      text: 'Mapping Your Wallet Address to Your Profile',
      type: WEB3_TRANSACTION,
    }, // step 7. map user profile to user address in registry
    {
      stepId: 8,
      status: STEP_NOT_STARTED,
      text: 'Registering Your Profile',
      type: WEB3_TRANSACTION,
    },
  ],
  [CREATE_ORG_PROFILE_TXNS]: [
    {
      stepId: 1,
      status: STEP_NOT_STARTED,
      text: 'Deploying Org Profile',
      type: WEB3_TRANSACTION,
    },
    {
      stepId: 2,
      status: STEP_NOT_STARTED,
      text: 'Adding Org Profile to Registry',
      type: WEB3_TRANSACTION,
    },
    {
      stepId: 3,
      status: STEP_NOT_STARTED,
      text: 'Setting Org Profile Data in DB',
      type: DB_UPLOAD,
    },
  ],
  [UPDATE_USER_PROFILE_TXNS]: [
    {
      stepId: 1,
      status: STEP_NOT_STARTED,
      text: 'Uploading Updated Profile Data in DB',
      type: DB_UPLOAD,
    }, //step 1. For now updating in DB only
    // {
    //   stepId: 1,
    //   status: STEP_NOT_STARTED,
    //   text: 'Uploading Updated Profile Data to IPFS',
    //   type: IPFS_UPLOAD,
    // }, //step 1. upload data to IPFS
    // {
    //   stepId: 2,
    //   status: STEP_NOT_STARTED,
    //   text: 'Storing Updated Profile URL on Your Profile 🔑',
    //   type: WEB3_TRANSACTION,
    // }, //step 2. Update metadata in Profile contract
  ],
  [UPDATE_ORG_PROFILE_TXNS]: [
    {
      stepId: 1,
      status: STEP_NOT_STARTED,
      text: 'Uploading Updated Profile Data in DB',
      type: DB_UPLOAD,
    }, //step 1. For now updating in DB only
    // {
    //   stepId: 1,
    //   status: STEP_NOT_STARTED,
    //   text: 'Uploading Updated Org Profile Data to IPFS',
    //   type: IPFS_UPLOAD,
    // }, //step 1. upload data to IPFS
    // {
    //   stepId: 2,
    //   status: STEP_NOT_STARTED,
    //   text: 'Storing Updated Profile URL on Your Org Profile 🔑',
    //   type: WEB3_TRANSACTION,
    // }, //step 2. Update metadata in Profile contract
  ],
  [ADD_ADMIN_TXNS]: [
    {
      stepId: 1,
      status: STEP_NOT_STARTED,
      text: 'Granting Admin Access 🔑',
      type: WEB3_TRANSACTION,
    }, //step 1. upload data to IPFS
    // {
    //   stepId: 2,
    //   status: STEP_NOT_STARTED,
    //   text: 'Indexing Org to New Admin Profile',
    //   type: WEB3_TRANSACTION,
    // }, //step 2. Update metadata in Profile contract
  ],
  [ADD_ADMIN_TO_USER_TXNS]: [
    {
      stepId: 1,
      status: STEP_NOT_STARTED,
      text: 'Granting Admin Access 🔑',
      type: WEB3_TRANSACTION,
    }, //step 1. upload data to IPFS
    {
      stepId: 2,
      status: STEP_NOT_STARTED,
      text: 'Mapping Wallet Address to Your Profile',
      type: WEB3_TRANSACTION,
    }, // step 7. map user profile to user address in registry
  ],
  [REMOVE_ADMIN_TXNS]: [
    {
      stepId: 1,
      status: STEP_NOT_STARTED,
      text: 'Revoking Admin Access 🔑',
      type: IPFS_UPLOAD,
    }, //step 1. upload data to IPFS
    // {
    //   stepId: 2,
    //   status: STEP_NOT_STARTED,
    //   text: 'Removing Org from Old Admin Profile',
    //   type: WEB3_TRANSACTION,
    // }, //step 2. Update metadata in Profile contract
  ],
  [REMOVE_ADMIN_FROM_USER_TXNS]: [
    {
      stepId: 1,
      status: STEP_NOT_STARTED,
      text: 'Revoking Admin Access 🔑',
      type: IPFS_UPLOAD,
    }, //step 1. upload data to IPFS
    {
      stepId: 2,
      status: STEP_NOT_STARTED,
      text: 'Unmapping wallet address',
      type: WEB3_TRANSACTION,
    }, //step 1. upload data to IPFS
  ],
  [CREATE_CHALLENGE_TXNS]: [
    {
      stepId: 1,
      status: STEP_NOT_STARTED,
      text: 'Uploading Challenge Data to Arweave',
      type: IPFS_UPLOAD,
    },
    {
      stepId: 2,
      status: STEP_NOT_STARTED,
      text: 'Registering Challenge in Registry',
      type: WEB3_TRANSACTION,
    },
    {
      stepId: 3,
      status: STEP_NOT_STARTED,
      text: 'Indexing Challenge to Your Org 🔑',
      type: WEB3_TRANSACTION,
    },
  ],
  [UPDATE_CHALLENGE_TXNS]: [
    {
      stepId: 1,
      status: STEP_NOT_STARTED,
      text: 'Uploading Updated Challenge Data to Arweave',
      type: IPFS_UPLOAD,
    },
    {
      stepId: 2,
      status: STEP_NOT_STARTED,
      text: 'Indexing Updated Challenge Details to Your Org 🔑',
      type: WEB3_TRANSACTION,
    },
  ],
  [CREATE_SUBMISSION_TXNS]: [
    {
      stepId: 1,
      status: STEP_NOT_STARTED,
      text: ' Uploading Asset to Arweave',
      type: WEB3_TRANSACTION,
    },
    {
      stepId: 2,
      status: STEP_NOT_STARTED,
      text: 'Verifying Signature ',
      type: WEB3_TRANSACTION,
    },
    {
      stepId: 3,
      status: STEP_NOT_STARTED,
      text: 'Minting Submission NFT',
      type: WEB3_TRANSACTION,
    },
    {
      stepId: 4,
      status: STEP_NOT_STARTED,
      text: 'Publishing Submission on Ocean',
      type: WEB3_TRANSACTION,
    },
    {
      stepId: 5,
      status: STEP_NOT_STARTED,
      text: 'Registering Submission in Registry',
      type: WEB3_TRANSACTION,
    },
  ],
  [CREATE_REVIEW_WITH_COMMENT]: [
    {
      stepId: 1,
      status: STEP_NOT_STARTED,
      text: 'Registering review in Registry',
      type: WEB3_TRANSACTION,
    },
  ],
  [CREATE_REVIEW_WITHOUT_COMMENT]: [
    {
      stepId: 1,
      status: STEP_NOT_STARTED,
      text: 'Registering review in Registry',
      type: WEB3_TRANSACTION,
    },
  ],
  [GET_DOWNLOAD_URL]: [
    {
      stepId: 1,
      status: STEP_NOT_STARTED,
      text: 'Getting Datatoken from Dispenser',
      type: WEB3_TRANSACTION,
    },
    {
      stepId: 2,
      status: STEP_NOT_STARTED,
      text: 'Getting Dataset Url',
      type: WEB3_TRANSACTION,
    },
  ],
  [DATASET_DOWNLOADING]: [
    {
      stepId: 1,
      status: STEP_NOT_STARTED,
      text: 'Dataset is being downloaded',
      type: WEB3_TRANSACTION,
    },
  ],
  [CLAIM_CERTIFICATE_TXNS]: [
    {
      stepId: 1,
      status: STEP_NOT_STARTED,
      text: 'Minting NFT Certificate 🔑',
      type: WEB3_TRANSACTION,
      stage: 'minting',
    },
    {
      stepId: 2,
      status: STEP_NOT_STARTED,
      text: 'Generating Certificate',
      type: IPFS_UPLOAD,
      stage: 'generating',
    },
    {
      stepId: 3,
      status: STEP_NOT_STARTED,
      text: 'Uploading to Irys Network',
      type: IPFS_UPLOAD,
      stage: 'uploading',
    },
  ],
};
