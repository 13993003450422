import { Button } from 'flowbite-react';
import CommonModal from '../CommonModal';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import LoadingDots from '../LoadingDots';
import rehypeRaw from 'rehype-raw';

const markdownContent = ``;

interface NewFeatureModalProps {
  showModal: boolean;
  defaultHeading: string;
  heading?: string;
  detailsMarkdown?: string;
  buttonText?: string;
  onClick: () => void;
  buttonLoading?: boolean;
}
const NewFeatureModal = ({
  showModal,
  defaultHeading,
  heading,
  detailsMarkdown,
  buttonText,
  onClick,
  buttonLoading,
}: NewFeatureModalProps) => {
  return (
    <>
      <CommonModal showModal={showModal}>
        <h2 className='text-2xl font-medium text-[#444] dark:text-white mt-5'>
          {heading ? heading : defaultHeading}
        </h2>
        <div className='border border-gray-200 dark:border-gray-600 rounded-xl mt-2'>
          <Markdown
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}
            className='dark:text-white p-4 pt-3'
          >
            {detailsMarkdown ? detailsMarkdown : markdownContent}
          </Markdown>
        </div>
        <Button
          disabled={buttonLoading}
          className={`mt-6 ml-auto ${buttonLoading ? 'cursor-progress p-2' : ''}`}
          onClick={onClick}
        >
          {buttonLoading ? <LoadingDots text={'Please wait'} /> : buttonText}
        </Button>
      </CommonModal>
    </>
  );
};

export default NewFeatureModal;
