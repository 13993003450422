import { useSelector } from 'react-redux';
import { Profile, RootState, useProfileType } from '../../@types';
import { apolloClient } from '../../App';
import { ALL_ORGANISATIONS } from '../../graphql/orgs';
import { useEffect, useState } from 'react';

export const useProfile: useProfileType = (profileId) => {
  const usersList = useSelector((state: RootState) => state.members.membersList);
  // const orgsList = useSelector((state: RootState) => state.organization.organizationList);
  const [allOrgs, setAllOrgs] = useState([]);
  const cachedData = apolloClient.readQuery({
    query: ALL_ORGANISATIONS,
  });

  useEffect(() => {
    if (cachedData?.fetchAllOrganisationsPage?.length > 0) {
      setAllOrgs(
        cachedData?.fetchAllOrganisationsPage?.map((org) => {
          return {
            ...org,
            ...org?.profile,
            isOrganization: true,
            profileId: org?.id,
          };
        }),
      );
    }
  }, [cachedData]);

  const selectedProfile: Profile = [...usersList, ...allOrgs].find(
    (profile) => profile?.id === profileId,
  );
  if (selectedProfile && profileId) return { selectedProfile, isProfileAvailable: true };
  return { isProfileAvailable: false, selectedProfile: Object() };
};
