import React, { useEffect, useRef, useState } from 'react';
import { Sidebar, Spinner, useTheme } from 'flowbite-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as GoogleIcon } from '../../assets/google.svg';
import { ReactComponent as GithubIcon } from '../../assets/github.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/linkedin.svg';
import { ReactComponent as DiscordIcon } from '../../assets/discord.svg';
import { FaUser, FaSignOutAlt, FaSignInAlt } from 'react-icons/fa';
import { SidebarProfileDropdown } from './SidebarProfileDropdown';
import { SidebarprofileHeader } from './Sidebarprofileheader';
import { RenderSidebarList } from './RenderSidebarList';
import { AppDispatch, Profile, RootState, sideNavItem } from '../../@types';
import { isMobile } from 'react-device-detect';
import {
  guestNavBarOrgLists,
  GUEST_PROFILE_CREATE_PATH,
  orgSidebarOptions,
  userSidebarOptions,
  userNavBarOrgLists,
  USER_PROFILE_DETAILS_PATH,
  ORG_PROFILE_DETAILS_PATH,
  SIGNUP_PATH,
  WALLET_CONNECT_WARNING_TEXT,
  WALLET_BUTTON_WARNING_TEXT,
  GUEST_CHALLENGE_LIST_PATH,
} from '../../Utils/Constants';
import CommonButton from '../../Components/CommonButton';

import {
  createUserInDB,
  // fetchPasskeysFromDB,
  fetchUserFromDB,
  fetchuserProfileId,
  // isPasskeyCreated,
} from '../../Utils/Helpers';
import { useAccount } from 'wagmi';
import { useQuery } from '@apollo/client';
import { ALL_ORGANISATIONS } from '../../graphql/orgs';
import ConnectWallet from '../../Components/ConnectWallet';
import { ReactComponent as DarkLogo } from '../../assets/desights-logo-black.svg';
import { ReactComponent as DLogo } from '../../assets/desights-D-black.svg';
import { ReactComponent as LightLogo } from '../../assets/desights-new-logo-white.svg';
import {
  getClient,
  signInWithDiscord,
  signInWithGithub,
  signInWithGoogle,
  signInWithLinkedIn,
} from '../../Utils/Config/supabase.config';
// import { createPasskey, loadPasskeysFromLocalStorage } from '../../Utils/passkeys';
// import { getAddressFromPasskey } from '../../Utils/Config/mintNFT';
import { updateProfileDetails } from '../../Store/Slices/ProfileSlice';
import { updateGuestState } from '../../Store/Slices/UtilsSlice';
import CommonModal from '../../Components/CommonModal';
import { useDisconnect } from 'wagmi';
import { resetRootState } from '../../Store';
import { HiOutlineExclamationCircle, HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { useSignInTracking } from '../../Utils/Hooks/useSignInTracking';
import CommonWarningModal from '../../Components/Modals/CommonWarningModal';
// import smallLogoWhite from '../../assets/desight-small-logo-white.png';
const SideBar = ({ className }) => {
  const isOrgSelected = useSelector((state: RootState) => state.utils.isOrgSelected);
  const currentProfile = useSelector((state: RootState) => state.profile.currentProfile);
  const defaultProfile = useSelector((state: RootState) => state.profile.defaultProfile);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [provider, setProvider] = useState('');
  const dispatch: AppDispatch = useDispatch();
  const { mode } = useTheme();

  const [organizationList, setAllOrganizationList] = useState([]);
  const { data: allOrg } = useQuery(ALL_ORGANISATIONS, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });
  const { disconnect } = useDisconnect();
  useEffect(() => {
    if (allOrg?.fetchAllOrganisationsPage?.length > 0) {
      setAllOrganizationList(
        allOrg.fetchAllOrganisationsPage?.map((org) => {
          return {
            ...org,
            ...org?.profile,
            isOrganization: true,
            profileId: org?.id,
          };
        }),
      );
    }
  }, [allOrg]);

  const isGuest = useSelector((state: RootState) => state.utils.isGuest);
  const [currentUserProfiles, setCurrentUserProfiles] = useState<Profile[]>([]);
  const [enableProfileDropdown, setEnableProfileDropdown] = React.useState(false);
  const [renderSidebar, setRenderSidebar] = React.useState<sideNavItem[]>([]);

  const [showListselected, setShowListSelected] = React.useState<{
    login: boolean;
    key: number;
    imgSrc: string;
  }>({
    login: true,
    key: 0,
    imgSrc: 'https://m.media-amazon.com/images/I/51VOjZGpinL._UX569_.jpg',
  });
  const navigate = useNavigate();
  const { isConnected, address: userAddress } = useAccount();
  const [showCreateButton, setShowCreateButton] = useState(false);
  useEffect(() => {
    //
    const user = localStorage.getItem('selecteduser');
    if (!isGuest && user) {
      setShowListSelected({
        ...showListselected,
        key: parseInt(user),
        login: !parseInt(user),
      });
    }
  }, [isGuest]);

  useEffect(() => {
    if (isGuest) setRenderSidebar(guestNavBarOrgLists);
    else if (isOrgSelected)
      setRenderSidebar([
        {
          path: [ORG_PROFILE_DETAILS_PATH, currentProfile.profileId].join('/'),
          name: 'Profile',
          icon: FaUser,
        },
        ...orgSidebarOptions,
      ]);
    else
      setRenderSidebar([
        {
          path: [USER_PROFILE_DETAILS_PATH, defaultProfile.profileId].join('/'),
          name: 'My Profile',
          icon: FaUser,
        },
        ...userSidebarOptions,
      ]);
  }, [isOrgSelected, isGuest, defaultProfile.profileId, currentProfile.profileId]);

  useEffect(() => {
    if (defaultProfile.organizations.length) {
      setCurrentUserProfiles(
        organizationList.filter((org: Profile) => defaultProfile?.organizations?.includes(org.id)),
      );
    } else {
      setCurrentUserProfiles([]);
    }
  }, [defaultProfile, organizationList, currentProfile]);

  const showCreateButtonHandler = async () => {
    // TO SHOW CREATE PROFILE BUTTON IN SIDE BAR IF WALLET IS CONNECTED AND NO USERID IS FOUND
    const userProfileId = await fetchuserProfileId(userAddress);
    userProfileId ? setShowCreateButton(false) : setShowCreateButton(true);
  };

  useEffect(() => {
    isConnected && showCreateButtonHandler();
  }, [isConnected, userAddress]);

  // sidebar toggle
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth >= 1024);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    if (!sidebarOpen && isMobile) {
      setIsCollapsed(false);
    }
  };
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        // Check if clicked element is either of the toggle buttons
        const toggleButton = document.querySelector('[data-drawer-toggle="default-sidebar"]');
        const desktopToggleButton = document.querySelector('[data-desktop-toggle="sidebar"]');

        if (
          (toggleButton && toggleButton.contains(event.target)) ||
          (desktopToggleButton && desktopToggleButton.contains(event.target))
        ) {
          return; // Don't close if clicking the toggle buttons
        }

        // Handle mobile view
        if (window.innerWidth < 1024 && sidebarOpen) {
          setSidebarOpen(false);
        }

        // Handle desktop view
        if (window.innerWidth >= 1024 && !isCollapsed) {
          setIsCollapsed(true);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebarOpen, isCollapsed]);

  useEffect(() => {
    const supabase = getClient;
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (
        event === 'SIGNED_IN' &&
        localStorage.getItem('persistedProvider') != null &&
        localStorage.getItem('session') == null
      ) {
        localStorage.setItem('session', session.access_token);
        setProvider(localStorage.getItem('persistedProvider'));
        setLoading(true);
        setShowSignUpModal(true);

        setOrGetUserData(session.user);
      }
    });

    async function setOrGetUserData(user) {
      try {
        const userData = await fetchUserFromDB(user.email);
        if (userData) {
          dispatch(
            updateProfileDetails({
              id: userData.id,
              name: userData.data.name,
              email: userData.data.email,
              profileId: userData.id,
              profileImage: userData.data.profileImage,
            }),
          );

          await trackSignIn({
            provider: localStorage.getItem('persistedProvider').toLowerCase(),
            status: 'success',
            email: userData.data.email,
            userId: userData.id,
          });
        } else {
          const undeployedNewUser = await createUserInDB(user);
          dispatch(
            updateProfileDetails({
              id: undeployedNewUser.id,
              name: user?.user_metadata?.name,
              email: user?.email,
              profileId: undeployedNewUser.id,
              profileImage: user?.user_metadata?.avatar_url,
            }),
          );
        }
        localStorage.removeItem('session');
        localStorage.removeItem('persistedProvider');
        dispatch(updateGuestState(false));
        setLoading(false);
        setShowSignUpModal(false);
      } catch (error) {
        console.error('Error setting user data:', error);
        await trackSignIn({
          provider: localStorage.getItem('persistedProvider')?.toLowerCase() || 'unknown',
          status: 'failed',
          email: user?.email || '',
          userId: '',
        });
        setLoading(false);
        setShowSignUpModal(false);
      }
    }
    if (localStorage.getItem('persistedProvider')) {
      const queryParams = new URLSearchParams(window.location.search);
      const hasError = queryParams.has('error');
      if (hasError) {
        setLoginError(true);
        setShowSignUpModal(true);
      }
    }

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const handleSignUpModal = () => {
    localStorage.removeItem('persistedProvider');
    setShowSignUpModal(true);
  };
  const { trackSignIn } = useSignInTracking();
  const handleSignIn = async (providerName: string) => {
    localStorage.removeItem('session');
    localStorage.setItem('persistedProvider', providerName);
    setProvider(providerName);
    setLoading(true);

    try {
      switch (providerName) {
        case 'Google':
          await signInWithGoogle();
          break;
        case 'Github':
          await signInWithGithub();
          break;
        case 'LinkedIn':
          await signInWithLinkedIn();
          break;
        case 'Discord':
          await signInWithDiscord();
          break;
        default:
          throw new Error('Unknown provider');
      }
      // Success tracking is handled in setOrGetUserData
    } catch (error) {
      console.error(`${providerName} sign-in failed:`, error);
      await trackSignIn({
        provider: providerName.toLowerCase(),
        status: 'failed',
        email: '',
        userId: '',
      });
      setLoading(false);
    }
  };
  const handleWalletConnect = async () => {
    console.log('Sidebar: Starting wallet connect tracking');
    try {
      await trackSignIn({
        provider: 'wallet',
        status: 'success', // Initial tracking as success
        email: '',
        userId: '',
      });
      console.log('Sidebar: Successfully tracked wallet connect');
    } catch (error) {
      console.error('Sidebar: Error tracking wallet connect:', error);
      await trackSignIn({
        provider: 'wallet',
        status: 'failed',
        email: '',
        userId: '',
      });
    }
    console.log('Sidebar: Starting wallet connect');
  };
  const handleSignOut = async () => {
    try {
      // Perform sign-out actions without tracking
      disconnect();
      const supabase = getClient;
      await supabase.auth.signOut();
      dispatch(resetRootState());
      dispatch(updateGuestState(true));

      // Clear storage
      localStorage.removeItem('persistedstate');
      localStorage.removeItem('session');

      setLoading(false);
      navigate(GUEST_CHALLENGE_LIST_PATH);
    } catch (error) {
      console.error('Error during sign out:', error);
      setLoading(false);
    }
  };

  // Initialize state based on screen width only

  // Handle screen resize
  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.innerWidth < 1024;
      if (isMobileView) {
        setIsCollapsed(false);
      } else {
        setIsCollapsed(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Simplified desktop collapse handler
  const handleDesktopCollapse = () => {
    if (window.innerWidth >= 1024) {
      setIsCollapsed(!isCollapsed);
    }
  };

  const [showWarningModal, setShowWarningModal] = useState(false);

  const handleCreateAccount = () => {
    if (isMobile) {
      setShowWarningModal(true);
      return;
    }
    navigate(SIGNUP_PATH);
  };

  return (
    <>
      <div
        className={`h-full relative transition-all duration-300 ${
          isCollapsed && !isMobile ? 'w-20' : 'w-auto lg:w-64'
        } ${className}`}
      >
        <button
          onClick={toggleSidebar}
          data-drawer-target='default-sidebar'
          data-drawer-toggle='default-sidebar'
          aria-controls='default-sidebar'
          type='button'
          className={`fixed top-1/2 -translate-y-1/2 z-50 inline-flex items-center justify-center p-1 text-sm text-white rounded-full lg:hidden bg-primary-700 hover:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-white dark:bg-primary-700 dark:hover:bg-primary-800 dark:focus:ring-gray-600 w-6 h-6 transition-all duration-300`}
          style={{
            left: sidebarOpen ? '264px' : '10px',
          }}
        >
          {sidebarOpen ? <HiChevronLeft size={16} /> : <HiChevronRight size={16} />}
        </button>

        <button
          onClick={handleDesktopCollapse}
          data-desktop-toggle='sidebar'
          className='hidden lg:flex fixed top-1/2 -translate-y-1/2 z-50 items-center justify-center w-6 h-6 rounded-full bg-primary-700 text-white cursor-pointer hover:bg-primary-800 transition-all duration-300'
          style={{
            left: isCollapsed ? '72px' : '245px',
          }}
        >
          {isCollapsed ? <HiChevronRight size={16} /> : <HiChevronLeft size={16} />}
        </button>

        <aside
          ref={sidebarRef}
          className={`fixed top-0 left-0 z-40 h-screen transition-all duration-300 ${
            sidebarOpen ? '' : '-translate-x-full lg:translate-x-0'
          } ${isCollapsed && !isMobile ? 'w-20' : 'w-64'}`}
          id='default-sidebar'
          aria-label='Sidenav'
        >
          <Sidebar
            aria-label='Sidebar with multi-level dropdown example'
            className={`h-full overflow-y-auto ${isCollapsed && !isMobile ? '!w-20 px-0' : ''}`}
          >
            <Sidebar.CTA className={`!p-0 ${isCollapsed && !isMobile ? '!w-20' : ''}`}>
              <div className='flex justify-center items-center dark:bg-primary-800'>
                <div className={`w-full ${isCollapsed && !isMobile ? 'px-2' : ''}`}>
                  {isGuest && location.pathname !== GUEST_PROFILE_CREATE_PATH ? (
                    <>
                      {showCreateButton && isConnected && (
                        <CommonButton
                          buttonText={isCollapsed && !isMobile ? '+' : 'Create My Account'}
                          buttonClassName='w-full'
                          onClick={handleCreateAccount}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <SidebarprofileHeader
                        showListselected={showListselected}
                        currentUserOrg={currentUserProfiles}
                        setEnableProfileDropdown={setEnableProfileDropdown}
                        enableProfileDropdown={enableProfileDropdown}
                        isCollapsed={isCollapsed && !isMobile}
                      />
                      {enableProfileDropdown && (
                        <SidebarProfileDropdown
                          ProfileUserData={[defaultProfile, ...currentUserProfiles]}
                          showListselected={showListselected}
                          setEnableProfileDropdown={setEnableProfileDropdown}
                          isCollapsed={isCollapsed && !isMobile}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Sidebar.CTA>

            {!isGuest && <hr className='border-gray-200 dark:border-gray-700 my-2' />}

            <Sidebar.Items>
              <Sidebar.ItemGroup className={isCollapsed && !isMobile ? '!space-y-2' : ''}>
                {!isGuest && (
                  <RenderSidebarList
                    setSidebarOpen={setSidebarOpen}
                    renderSidebar={renderSidebar}
                    isCollapsed={isCollapsed && !isMobile}
                  />
                )}
                {!isOrgSelected && (
                  <>
                    <hr className='border-gray-200 sm:mx-auto dark:border-headingColor my-2' />
                    <RenderSidebarList
                      setSidebarOpen={setSidebarOpen}
                      renderSidebar={isGuest ? guestNavBarOrgLists : userNavBarOrgLists}
                      isCollapsed={isCollapsed && !isMobile}
                    />
                  </>
                )}
                <hr className='border-gray-200 sm:mx-auto dark:border-headingColor my-2' />

                <div
                  className={`flex ${isCollapsed && !isMobile ? 'justify-center' : 'justify-between'} items-center pt-1`}
                >
                  <div
                    className={`flex flex-col ${isCollapsed && !isMobile ? 'items-center w-full' : 'items-center'}`}
                  >
                    {isConnected && (
                      <div
                        className={`${isConnected ? (isCollapsed && !isMobile ? 'mx-auto' : 'ml-4') : ''}`}
                      >
                        <ConnectWallet
                          buttonText={isCollapsed && !isMobile ? '+' : 'Create My Profile'}
                          responsive={true}
                          setLoginError={setLoginError}
                          setShowSignUpModal={setShowSignUpModal}
                          isCollapsed={isCollapsed && !isMobile}
                        />
                      </div>
                    )}
                    <div
                      className={`${
                        isCollapsed && !isMobile ? 'mx-auto justify-center items-center' : 'ml-4'
                      } mt-4 mb-[80px] flex justify-center`}
                    >
                      {currentProfile.id == '' && currentProfile.profileId == '' ? (
                        <CommonButton
                          buttonText={isCollapsed && !isMobile ? '' : 'Sign In'}
                          buttonClassName={`${
                            isCollapsed && !isMobile ? '!p-2 !w-10 h-10 mx-auto' : ''
                          }`}
                          ButtonIcon={FaSignInAlt}
                          onClick={handleSignUpModal}
                        />
                      ) : (
                        <CommonButton
                          buttonText={isCollapsed && !isMobile ? '' : 'Sign Out'}
                          buttonClassName={`${
                            isCollapsed && !isMobile ? '!p-2 !w-10 h-10 mx-auto' : ''
                          }`}
                          ButtonIcon={FaSignOutAlt}
                          onClick={handleSignOut}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Sidebar.ItemGroup>
            </Sidebar.Items>
          </Sidebar>

          <div
            className={`${
              isCollapsed ? 'w-20' : 'w-60'
            } bg-white fixed text-center bottom-0 left-0 dark:bg-primary-800 py-2 transition-all duration-300`}
          >
            {isCollapsed ? (
              <DLogo className='mx-auto w-9 h-9' />
            ) : mode === 'dark' ? (
              <DarkLogo
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
                className='desights-logo'
              />
            ) : (
              <LightLogo
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
                className='desights-logo bg-white'
              />
            )}
          </div>
        </aside>

        {sidebarOpen && (
          <div className='fixed top-0 left-0 w-screen h-screen bg-transparent z-30' />
        )}
        <CommonModal
          showModal={showSignUpModal}
          setShowModal={setShowSignUpModal}
          showClose={!loading && !loginError}
          bodyClassName='!rounded-[24px] dark:!border-[2px] dark:!border-primary-700 dark:bg-primary-900'
          closeClassName='dark:!text-primary-700 text-xl !rounded-[50%] !border-2 !border-gray-700 dark:!border-primary-700 !right-[20px] !top-[20px] !p-4'
        >
          <div
            className={
              !loginError
                ? 'flex flex-col items-center justify-center px-4 py-[40px]'
                : 'flex flex-col items-center justify-center pt-[10px]'
            }
          >
            {!loading && !loginError && (
              <>
                <h2 className='text-center text-xl font-700 dark:text-white'>Hello!</h2>
                <p className='text-center text-md text-gray-500 font-400 dark:text-white my-2'>
                  Please sign in to continue
                </p>
              </>
            )}
            {!loading && !loginError && (
              <div className=''>
                {currentProfile.profileId == '' && (
                  <div className='my-2'>
                    <ConnectWallet
                      buttonText='Connect With Wallet'
                      setShowSignUpModal={setShowSignUpModal}
                      setLoginError={setLoginError}
                      responsive={true}
                      onClick={handleWalletConnect}
                    />
                  </div>
                )}
                <div className='my-4 flex justify-center items-center'>
                  <hr className='w-[45%] border-gray-500 dark:border-headingColor' />
                  <p className='text-center text-sm text-gray-500 dark:text-headingColor mx-2'>
                    OR
                  </p>
                  <hr className='w-[45%] border-gray-500 dark:border-headingColor' />
                </div>

                <div className='my-4'>
                  <CommonButton
                    buttonClassName='w-full dark:!bg-white hover:dark:!bg-white dark:!text-black'
                    buttonText='SignIn with Google'
                    onClick={() => handleSignIn('Google')}
                    size='md'
                    ButtonIcon={GoogleIcon}
                  />
                </div>
                <div className='my-4'>
                  <CommonButton
                    buttonClassName='w-full dark:!bg-white hover:dark:!bg-white dark:!text-black'
                    buttonText='SignIn with Github'
                    onClick={() => handleSignIn('Github')}
                    size='md'
                    ButtonIcon={GithubIcon}
                  />
                </div>
                <div className='my-4'>
                  <CommonButton
                    buttonClassName='w-full dark:!bg-white hover:dark:!bg-white dark:!text-black'
                    buttonText='SignIn with LinkedIn'
                    onClick={() => handleSignIn('LinkedIn')}
                    size='md'
                    ButtonIcon={LinkedInIcon}
                  />
                </div>
                <div className='my-4'>
                  <CommonButton
                    buttonClassName='w-full dark:!bg-white hover:dark:!bg-white dark:!text-black'
                    buttonText='SignIn with Discord'
                    onClick={() => handleSignIn('Discord')}
                    size='md'
                    ButtonIcon={DiscordIcon}
                  />
                </div>
              </div>
            )}
            {loading && (
              <>
                <h3 className='text-[20px] sm:text-[24px] font-normal dark:text-primary-700 mb-3'>
                  Signing In {provider ? `Using ${provider}` : ''}
                </h3>
                <Spinner />
              </>
            )}
            {!loading && loginError && (
              <>
                <HiOutlineExclamationCircle className='mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200' />
                <h3 className='text-sm sm:text-[20px] font-normal dark:text-primary-700 mb-4'>
                  Signin Rejected. Please SignIn Again!
                </h3>
                <CommonButton
                  buttonClassName='dark:!bg-white hover:dark:!bg-white dark:!text-black'
                  buttonText='Ok'
                  onClick={() => {
                    localStorage.removeItem('persistedProvider');
                    setLoginError(false);
                    setShowSignUpModal(false);
                  }}
                  size='md'
                />
              </>
            )}
          </div>
        </CommonModal>
      </div>

      <CommonWarningModal
        buttonText={WALLET_BUTTON_WARNING_TEXT}
        show={showWarningModal}
        warningText={WALLET_CONNECT_WARNING_TEXT}
        onButtonClick={() => {
          setShowWarningModal(false);
        }}
      />
    </>
  );
};

export default SideBar;
