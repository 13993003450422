import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useChallengeType } from '../../@types';
// import { fetchChallengeAction } from "../../Store/Actions";
import { apolloClient } from '../../App';
import { GET_ALL_CHALLENGES } from '../../graphql/challenges';
import { FETCH_ALL_SUBMISSIONS_FOR_USER } from '../../graphql/submissions';
import { useQuery } from '@apollo/client';

export const useChallenge: useChallengeType = (challengeId, profileId) => {
  const [allCachedChallenges, setAllChallenges] = useState([]);
  const [matchingProposalId, setMachingProposalId] = useState('');
  const isOrgSelected = useSelector((state: RootState) => state.utils.isOrgSelected);
  // const challengeList = useSelector((state: RootState) => state.challenge.challengeList);
  const selectedChallenge =
    allCachedChallenges.find((challenge) => challenge?.id == challengeId) ?? {};
  // const matchingProposal = isOrgSelected
  //   ? ""
  //   : selectedChallenge?.submissions?.find((submissionID: string) =>
  //       selectedProfile?.submissions?.includes(submissionID)
  //     ) ?? "";

  const getSubsForUserHandler = async (profileId) => {
    const res = await apolloClient.query({
      query: FETCH_ALL_SUBMISSIONS_FOR_USER,
      variables: {
        profileAddress: profileId,
      },
      fetchPolicy: 'network-only',
    });

    if (res.data.fetchAllSubmissionsForGivenUser?.length > 0 && !isOrgSelected) {
      const subForCurrentChallenge = res.data?.fetchAllSubmissionsForGivenUser?.filter(
        (sub) => sub?.challengeId == challengeId,
      );
      setMachingProposalId(subForCurrentChallenge[0]?.id);
    }
  };

  // const cachedChallenges = apolloClient.readQuery({
  //   query: GET_ALL_CHALLENGES,
  // });

  const {
    loading,
    error,
    data: allChallenges,
  } = useQuery(GET_ALL_CHALLENGES, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (profileId) {
      getSubsForUserHandler(profileId);
    }
  }, [profileId, challengeId]);

  useEffect(() => {
    allChallenges?.fetchAllChallengesPage?.length > 0 &&
      setAllChallenges(allChallenges?.fetchAllChallengesPage);
  }, [allChallenges]);

  return { selectedChallenge, matchingProposal: matchingProposalId, loading, error };
};
