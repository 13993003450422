import React, { ReactNode } from 'react';
import ErrorBoundaryFallback from './ErrorBoundaryFallback';
import axios from 'axios';
import { DISCORD_ROLE_ID } from '../Utils/Constants';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = { hasError: false };

  static getDerivedStateFromError(error: Error) {
    console.log(error);
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.log(error);
    console.log('errorInfo', errorInfo);
    const apiUrl = process.env.REACT_APP_DISCORD_HOOK;

    axios
      .post(apiUrl, {
        content:
          `<@&${DISCORD_ROLE_ID}>\n${error.name}: ${error.message}` +
          '\n' +
          '```' +
          errorInfo.componentStack +
          '```',
      })
      .then((response) => {
        console.log('Message sent successfully:', response.data);
      })
      .catch((err) => {
        console.error('Error sending message:', err);
      });
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return <ErrorBoundaryFallback />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
