import { NavigateFunction } from 'react-router-dom';
import { AppDispatch, Profile } from '../../@types';
import { USER_PROFILE_DETAILS_PATH } from '../../Utils/Constants';
import {
  createOnChainUserProfile,
  fetchUserOrgs,
  fetchUserProfileData,
  updateOnChainUserProfile,
} from '../../Utils/Helpers';
import { updateMember, addOrgsToMember } from '../Slices/MemberSlice';
import { updateProfileDetails } from '../Slices/ProfileSlice';
import { updateOrgSelected } from '../Slices/UtilsSlice';
import { fetchMultipleOrgsAction } from './OrganizationAction';
import { resetSidebarSyncDetailsAction } from './ProfileActions';

export const createUserProfileAction =
  (data: Profile, navigate: NavigateFunction, address: string) => async (dispatch: AppDispatch) => {
    // const profileId = getRandom()
    // dispatch(toggleTxnModalAction(CREATE_USER_PROFILE_TXNS));
    // tempStepMover(stepConfig[CREATE_USER_PROFILE_TXNS], () => { })

    const profileId = await createOnChainUserProfile(data, address, navigate, dispatch); // Create user profile API call.
    console.log('profile id', profileId);
    // YET TO IMPLEMENT NAVIGATE LOGIC AFTER TESTING CREATE PROFILE

    // if (profileId) {
    //   dispatch(addMember({ ...data, profileId: profileId }))
    //   dispatch(updateProfileDetails({ ...data, profileId: profileId }));
    //   dispatch(updateOrgSelected(false));
    //   dispatch(updateGuestStateAction(false));
    //   dispatch(resetSidebarSyncDetailsAction())
    //   navigate(USER_PROFILE_DETAILS_PATH + "/" + profileId);
    // }
  };

export const updateUserProfileAction =
  (data: Profile, navigate: NavigateFunction) => async (dispatch: AppDispatch) => {
    // const tx = { status: "status" };
    // dispatch(toggleTxnModalAction(UPDATE_USER_PROFILE_TXNS));
    // tempStepMover(stepConfig[UPDATE_USER_PROFILE_TXNS], onComplete)

    const complete = () => {
      dispatch(updateMember(data));
      dispatch(updateProfileDetails(data));
      dispatch(updateOrgSelected(false));
      navigate(USER_PROFILE_DETAILS_PATH + '/' + data.profileId);
      dispatch(resetSidebarSyncDetailsAction());
    };
    updateOnChainUserProfile(data, complete, navigate); // Update user profile API call.
  };

export const fetchUserProfileAction = (userAddress: string) => async (dispatch: AppDispatch) => {
  const profileData = await fetchUserProfileData(userAddress);
  dispatch(updateMember(profileData));
};

export const fetchUserOrgsAction = (userProfileId: string) => async (dispatch: AppDispatch) => {
  const userOrgs = await fetchUserOrgs(userProfileId);
  dispatch(fetchMultipleOrgsAction(userOrgs));
  dispatch(addOrgsToMember({ userOrgs, userProfileId }));
  // dispatch(addOrgsToProfile(userOrgs));
};
