import { apolloClient } from '../../App';
import { DocumentNode } from 'graphql';
export const subscriptionToEvent = (
  query: DocumentNode,
  nextFn: (data: string) => void,
  errorFn: (error: string) => void,
  completeFn: (data: string) => void,
  /**diabling eslint here because this is common function and there could be so many varible changes */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variables: { [key: string]: any },
  subName: string,
  txCompleteFn?: (data?: string) => void,
) => {
  const subscription = apolloClient.subscribe({
    query: query,
    variables: variables,
  });
  const subToEvent = subscription.subscribe({
    next: (response) => {
      // console.log('response from subscription in helper', response.data[`${subName}`]);
      nextFn(response.data[`${subName}`].data);
      if (response.data[`${subName}`].message === 'complete') {
        txCompleteFn();
        completeFn(response.data[`${subName}`].data);
        // alert('THIS IS 1')
        subToEvent.unsubscribe();
        // console.log(response.data[`${subName}`].data);
      }
    },
    error: (error: string) => {
      // Handle any subscription errors
      // alert('THIS IS 2')
      subToEvent.unsubscribe();
      errorFn(error);
      console.error('subscription error in helper', error);
    },
    complete: () => {
      // alert('THIS IS 3')
      subToEvent.unsubscribe();
      completeFn('');
    },
  });
  // setTimeout(()=>{
  //   alert('closeing')
  //   subToEvent.unsubscribe();
  // },8000)
  return subToEvent;
};
