import { gql } from '@apollo/client';

// Queries
export const GET_UNREAD_NOTIFICATIONS = gql`
  query GetUnreadNotifications($userId: String!) {
    getUnreadNotifications(user_id: $userId) {
      id
      type
      status
      payload {
        newLevel
        oldLevel
        oldXp
        finalXp
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_ALL_NOTIFICATIONS = gql`
  query GetAllNotifications($userId: String!, $limit: Int, $offset: Int) {
    getAllNotifications(user_id: $userId, limit: $limit, offset: $offset) {
      id
      type
      status
      payload {
        newLevel
        oldLevel
        oldXp
        finalXp
      }
      createdAt
      updatedAt
    }
  }
`;

// Mutations
export const CREATE_NOTIFICATION = gql`
  mutation CreateNotification(
    $userId: String!
    $type: NotificationType!
    $payload: NotificationPayloadInput!
  ) {
    createNotification(user_id: $userId, type: $type, payload: $payload) {
      id
      type
      status
      payload {
        newLevel
        oldLevel
        oldXp
        finalXp
      }
      createdAt
    }
  }
`;

export const MARK_NOTIFICATIONS_AS_READ = gql`
  mutation MarkNotificationsAsRead($userId: String!, $notificationIds: [ID!]) {
    markNotificationsAsRead(user_id: $userId, notificationIds: $notificationIds)
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($id: ID!) {
    deleteNotification(id: $id)
  }
`;

// Subscription
export const ON_NEW_NOTIFICATION = gql`
  subscription OnNewNotification($userId: String!) {
    onNewNotification(user_id: $userId) {
      id
      type
      status
      payload {
        newLevel
        oldLevel
        oldXp
        finalXp
      }
      createdAt
      updatedAt
    }
  }
`;
