import { useQuery, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { RootState } from '../../@types';
import { GET_UNREAD_NOTIFICATIONS, MARK_NOTIFICATIONS_AS_READ } from '../../graphql/notifications';

export const useNotifications = () => {
  const currentProfile = useSelector((state: RootState) => state.profile.defaultProfile);

  const shouldFetchNotifications =
    currentProfile && currentProfile.id && currentProfile.id.trim() !== '';

  const { data: unreadNotifications, refetch } = useQuery(GET_UNREAD_NOTIFICATIONS, {
    variables: { userId: currentProfile?.id },
    skip: !shouldFetchNotifications,
  });

  const [markAsRead] = useMutation(MARK_NOTIFICATIONS_AS_READ);

  const markNotificationsAsRead = async (notificationIds: string[]) => {
    if (!currentProfile?.id) return;

    try {
      await markAsRead({
        variables: {
          userId: currentProfile.id,
          notificationIds,
        },
      });
      await refetch();
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };

  return {
    unreadNotifications: unreadNotifications?.getUnreadNotifications || [],
    markNotificationsAsRead,
    refetchNotifications: refetch,
  };
};
