import { Button, ButtonProps, Spinner } from 'flowbite-react';
import { IconType } from 'react-icons';
import React from 'react';

export const colorObj = {
  failure: '!bg-red-600 dark:!bg-red-700 hover:!bg-red-700 dark:hover:!bg-red-800',
  warning: '!bg-yellow-400 dark:!bg-yellow-400 hover:!bg-yellow-500 dark:hover:!bg-yellow-500',
  info: '!bg-primary-500 dark:!bg-primary-700 hover:!bg-primary-600 dark:hover:!bg-primary-700',
  secondary:
    '!bg-secondary-500 dark:!bg-secondary-400 hover:!bg-secondary-500 dark:hover:!bg-secondary-500',
};

export interface CommonButtonProps extends ButtonProps {
  ButtonIcon?: IconType;
  buttonText?: string;
  buttonClassName?: string;
  children?: React.ReactNode;
  iconClassName?: string;
  showLoader?: boolean;
  buttonTextClass?: string;
  color?: keyof typeof colorObj;
}

const CommonButton: React.FC<CommonButtonProps> = ({
  ButtonIcon,
  buttonText,
  buttonClassName = '',
  children,
  iconClassName = '',
  showLoader = false,
  buttonTextClass = '',
  color = 'info',
  ...props
}) => {
  const colorClass = colorObj[color];
  const iconOnly = !buttonText && ButtonIcon;

  return (
    <Button
      size='sm'
      className={`self-center flex items-center justify-center gap-2 
        ${colorClass} 
        ${buttonClassName} 
        ${showLoader ? 'cursor-wait' : ''} 
        ${iconOnly ? 'p-2' : ''}`}
      {...props}
    >
      {showLoader ? (
        <span className='mr-1 sm:mr-2'>
          <Spinner size='sm' light={true} />
        </span>
      ) : ButtonIcon ? (
        <ButtonIcon
          className={`text-sm md:text-lg mr-1 sm:mr-2 ${iconClassName} ${
            iconOnly ? 'w-5 h-5' : 'w-4 h-4'
          }`}
        />
      ) : null}
      {buttonText && (
        <span className={`min-w-[60px] md:min-w-[80px] ${buttonTextClass}`}>{buttonText}</span>
      )}
      {children}
    </Button>
  );
};

export default CommonButton;
