import { Toast } from 'flowbite-react';
import { HiCheckCircle } from 'react-icons/hi2';
import { IoClose } from 'react-icons/io5';
import { Spinner } from 'flowbite-react';
import { CustomToastProps } from '../@types/Components';

const CustomToast = ({ text, setShowToast, loading = false }: CustomToastProps) => {
  return (
    <Toast
      className='justify-between gap-2 py-2 px-3 !bg-primary-50  dark:!bg-gray-700 fixed bottom-7 left-0 right-0 mx-auto z-50'
      duration={75}
    >
      <div className='flex gap-2'>
        {loading ? (
          <Spinner size='sm' className='text-primary-500' />
        ) : (
          <HiCheckCircle className='text-primary-500 dark:text-primary-500 text-xl' />
        )}
        <div className='text-sm font-normal dark:text-white'>{text}</div>
      </div>
      <IoClose
        onClick={() => {
          setShowToast(false);
        }}
        className='text-xl dark:text-white dark:hover:text-primary-500 hover:text-primary-500 cursor-pointer'
      />
    </Toast>
  );
};

export default CustomToast;
