import { useEffect, useState } from 'react';
import NewFeatureModal from './Modals/NewFeatureModal';
import { FETCH_LATEST_NEWS, FETCH_USER_PROFILE_DETAILS, UPDATE_LAST_SEEN } from '../graphql/users';
import { useMutation, useQuery } from '@apollo/client';
import { useAccount } from 'wagmi';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../@types';
import { updateDefaultProfile } from '../Store/Slices/ProfileSlice';
const CheckLatestFeatures = () => {
  const currentProfile = useSelector((state: RootState) => state.profile.defaultProfile);
  const dispatch: AppDispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [modalState, setModalState] = useState({
    buttonText: '',
    description: '',
    title: '',
    id: NaN,
  });
  const { isConnected } = useAccount();

  const { refetch } = useQuery(FETCH_LATEST_NEWS, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    skip: true,
  });

  const [updateLastSeen, { loading }] = useMutation(UPDATE_LAST_SEEN);

  useEffect(() => {
    if (isConnected && currentProfile?.id) {
      fetchDataHandler();
    }
  }, [isConnected, currentProfile?.newsLastSeen, currentProfile?.id]);

  const fetchDataHandler = async () => {
    const res = await refetch();
    const { description, title, button_text, id } = res.data.getLastVersion;

    /**New features will shop up only if id<newsLastSeen(i.d/version) */
    if (
      currentProfile?.id &&
      res?.data?.getLastVersion &&
      res.data?.getLastVersion?.id > currentProfile?.newsLastSeen
    ) {
      setModalState({
        buttonText: button_text,
        description,
        title,
        id,
      });
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  };

  const updateLastSeenHandler = async () => {
    const res = await updateLastSeen({
      variables: {
        userId: currentProfile?.profileId,
        version: modalState?.id,
      },
      refetchQueries: [
        {
          query: FETCH_USER_PROFILE_DETAILS,
          variables: {
            id: currentProfile?.profileId,
          },
        },
      ],
    });
    dispatch(updateDefaultProfile({ ...currentProfile, newsLastSeen: modalState.id }));
    setShowModal(false);
    console.log(res?.data);
  };
  return (
    <>
      <NewFeatureModal
        showModal={showModal}
        defaultHeading={'WHAT IS NEW....IDK'}
        buttonText={modalState?.buttonText ?? 'some button'}
        detailsMarkdown={modalState?.description ?? 'some description'}
        heading={modalState.title}
        buttonLoading={loading}
        onClick={() => {
          updateLastSeenHandler();
        }}
      />
    </>
  );
};

export default CheckLatestFeatures;
