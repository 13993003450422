import { UAParser } from 'ua-parser-js';

export interface UserProperties {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  browser?: string;
  browserVersion?: string;
  device?: string;
  deviceModel?: string;
  os?: string;
  osVersion?: string;
  screenResolution?: string;
  windowSize?: string;
  colorDepth?: string;
  language?: string;
  timeZone?: string;
  referrer?: string;
  sessionDuration?: number;
  city?: string;
  country?: string;
  region?: string;
  ip?: string;
  user_ip?: string;
}

export const getUtmParams = (): Partial<UserProperties> => {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    utm_source: urlParams.get('utm_source') || undefined,
    utm_medium: urlParams.get('utm_medium') || undefined,
    utm_campaign: urlParams.get('utm_campaign') || undefined,
    utm_term: urlParams.get('utm_term') || undefined,
    utm_content: urlParams.get('utm_content') || undefined,
  };
};

export const getDeviceInfo = (): Partial<UserProperties> => {
  const parser = new UAParser(window.navigator.userAgent);
  const result = parser.getResult();

  return {
    browser: result.browser.name,
    browserVersion: result.browser.version,
    device: result.device.type || 'desktop',
    deviceModel: result.device.model,
    os: result.os.name,
    osVersion: result.os.version,
  };
};

export const getScreenInfo = (): Partial<UserProperties> => {
  return {
    screenResolution: `${window.screen.width}x${window.screen.height}`,
    windowSize: `${window.innerWidth}x${window.innerHeight}`,
    colorDepth: `${window.screen.colorDepth}-bit`,
  };
};

export const getUserInfo = (): Partial<UserProperties> => {
  return {
    language: navigator.language,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    referrer: document.referrer,
  };
};

export const getLocationInfo = async (): Promise<Partial<UserProperties>> => {
  try {
    const response = await fetch('https://ipapi.co/json/');
    const data = await response.json();

    console.log('Location data received:', data); // For debugging

    const locationInfo = {
      ip: data.ip,
      user_ip: data.ip,
      city: data.city,
      country: data.country_name,
      region: data.region,
    };

    console.log('Processed location info:', locationInfo); // For debugging
    return locationInfo;
  } catch (error) {
    try {
      const ipResponse = await fetch('https://api.ipify.org?format=json');
      const ipData = await ipResponse.json();

      console.log('Fallback IP data:', ipData); // For debugging
      return {
        ip: ipData.ip,
        user_ip: ipData.ip,
      };
    } catch (fallbackError) {
      console.error('Error fetching location and IP info:', error);
      console.error('Fallback IP fetch also failed:', fallbackError);
      return {};
    }
  }
};

export const SIGNIN_ACTIVITY = 'SIGNIN_ACTIVITY';
export const CHALLENGE_VIEW_ACTIVITY = 'CHALLENGE_VIEW_ACTIVITY';
