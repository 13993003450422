import { HiOutlineXCircle, HiOutlineCheckCircle } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../@types';
import { toggleTxnModal } from '../../Store/Slices/UtilsSlice';
import CommonModal from '../CommonModal';
import TxnSteps from './TxnSteps';
import { useEffect, useState } from 'react';

const TxnModal = () => {
  const dispatch: AppDispatch = useDispatch();
  const [shouldShowCross, setShouldShowCross] = useState(false);
  const { tempStatus, showTxnModal, currentStepsTitle, successMessage, errorMessage } = useSelector(
    (state: RootState) => state.utils,
  );
  const toggleModal = () => {
    dispatch(toggleTxnModal(''));
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderTransaction = (transactionStatus: string) => {
    // console.log(transactionStatus);
    switch (tempStatus) {
      case 'success':
        return (
          <div className='text-center'>
            <div className='text-lg font-medium py-2 dark:text-white'>Success!!!</div>
            <HiOutlineCheckCircle className='text-primary-500 text-7xl mx-auto' />
            <div className='text-gray-500 dark:text-gray-400'>{successMessage}</div>
          </div>
        );
      case 'failure':
        return (
          <div className='text-center'>
            <div className='text-lg font-medium py-2 dark:text-white'>Something isn't right :(</div>
            <HiOutlineXCircle className='text-red-500 text-7xl mx-auto' />
            <div className='text-gray-500 dark:text-gray-400'>{errorMessage}</div>
          </div>
        );
      default:
        return <TxnSteps />;
    }
  };

  useEffect(() => {
    setShouldShowCross(Boolean(tempStatus));
  }, [tempStatus]);

  return (
    <CommonModal
      size='md'
      setShowModal={toggleModal}
      showModal={showTxnModal}
      title={currentStepsTitle}
      showClose={shouldShowCross}
    >
      {renderTransaction('')}
    </CommonModal>
  );
};

export default TxnModal;
