/* form fields */
import { allCountries } from './allcountries';
export const NEW_ORGANISATION_FORM_FIELDS = [
  {
    type: 'text',
    label: 'Name',
    attributeName: 'name',
    helperTextClassName: 'text-red-600',
    placeholder: 'Name',
    required: true,
    labelhelperText: '*',
  },
  {
    type: 'text',
    label: 'Email',
    attributeName: 'email',
    helperTextClassName: 'text-red-600',
    placeholder: 'Email',
    required: true,
    labelhelperText: '*',
  },
  {
    type: 'textArea',
    label: 'Short Bio',
    htmlFor: 'Short Bio',
    attributeName: 'bio',
    placeholder: 'Describe your Organisation in 200 characters..',
    helperTextClassName: 'text-red-600',
    maxLength: 200,
    required: true,
    labelhelperText: '*',
  },
  {
    type: 'text',
    label: 'Industry',
    attributeName: 'industry',
    helperTextClassName: 'text-red-600',
    placeholder: 'e.g. Decentralised Finance',
    required: true,
    labelhelperText: '*',
  },
  {
    type: 'text',
    label: 'Website',
    attributeName: 'website',
    helperTextClassName: 'text-red-600',
    placeholder: 'https.//www.awesomedao.com',
    required: true,
    labelhelperText: '*',
  },
  {
    type: 'text',
    label: 'Twitter Profile',
    attributeName: 'twitter',
    helperTextClassName: 'text-red-600',
    placeholder: 'awesomedao',
    addon: 'https://twitter.com/',
  },
  {
    type: 'text',
    label: 'Discord Server Link',
    attributeName: 'discord',
    helperTextClassName: 'text-red-600',
    placeholder: 'enZFdaFMp',
    addon: 'https://discord.gg/',
  },
  {
    type: 'text',
    label: 'Telegram Channel Link',
    attributeName: 'telegram',
    helperTextClassName: 'text-red-600',
    placeholder: 'awesome_dao',
    addon: 'https://t.me/',
  },
  {
    type: 'text',
    label: 'GitHub Organization Link',
    attributeName: 'github',
    helperTextClassName: 'text-red-600',
    placeholder: 'awesomedao',
    addon: 'https://github.com/',
  },
  {
    type: 'text',
    label: 'Linkedin Page Link',
    attributeName: 'linkedin',
    helperTextClassName: 'text-red-600',
    placeholder: 'awesomedao',
    addon: 'https://www.linkedin.com/company/',
  },
];

export const NEW_PROFILE_FORM_FIELDS = [
  {
    type: 'text',
    label: 'Name',
    attributeName: 'name',
    helperTextClassName: 'text-red-600',
    placeholder: 'Name',
    showBottomText: 'Only letters, numbers and spaces are allowed',
    required: true,
    labelhelperText: '*',
  },
  {
    type: 'text',
    label: 'Email',
    attributeName: 'email',
    helperTextClassName: 'text-red-600',
    placeholder: 'Email',
  },
  {
    type: 'textArea',
    label: 'Short Bio',
    htmlFor: 'Short Bio',
    attributeName: 'bio',
    placeholder: 'Describe your Organization in 200 characters..',
    helperTextClassName: 'text-red-600',
    maxLength: 200,
  },
  {
    type: 'text',
    label: 'Website',
    attributeName: 'website',
    helperTextClassName: 'text-red-600',
    placeholder: 'https.//awesomedao.com',
  },
  {
    type: 'text',
    label: 'Twitter Link',
    attributeName: 'twitter',
    helperTextClassName: 'text-red-600',
    placeholder: 'awesomedao',
    addon: 'https://twitter.com/',
  },
  {
    type: 'text',
    label: 'Telegram Channel Link',
    attributeName: 'telegram',
    helperTextClassName: 'text-red-600',
    placeholder: 'awesome_dao',
    addon: 'https://t.me/',
  },
  {
    type: 'text',
    label: 'Kaggle Profile Link',
    attributeName: 'kaggle',
    helperTextClassName: 'text-red-600',
    placeholder: 'awesomedao',
    addon: 'https://www.kaggle.com/',
  },
  {
    type: 'text',
    label: 'GitHub Link',
    attributeName: 'github',
    helperTextClassName: 'text-red-600',
    placeholder: 'awesomedao',
    addon: 'https://github.com/',
  },
  {
    type: 'text',
    label: 'Linkedin Page Link',
    attributeName: 'linkedin',
    helperTextClassName: 'text-red-600',
    placeholder: 'awesomedao',
    addon: 'https://www.linkedin.com/in/',
  },
  {
    type: 'formSectionHeading',
    sectionHeading: 'INVOICE DETAILS',
  },
  {
    type: 'text',
    label: 'First Name',
    attributeName: 'firstname',
    helperTextClassName: 'text-red-600',
    placeholder: 'First Name',
    required: true,
    labelhelperText: '*',
    showBottomText: 'Only letters and spaces are allowed',
  },
  {
    type: 'text',
    label: 'Last Name',
    attributeName: 'lastname',
    helperTextClassName: 'text-red-600',
    placeholder: 'Last Name',
    required: true,
    labelhelperText: '*',
    showBottomText: 'Only letters and spaces are allowed',
  },
  {
    type: 'selectoption',
    label: 'Country',
    attributeName: 'country',
    helperTextClassName: 'text-red-600',
    placeholder: 'Country',
    required: true,
    labelhelperText: '*',
    selectOptions: allCountries.map((val) => val?.name), //getting countries name
  },
  {
    type: 'text',
    label: 'Payment Wallet (to receive Rewards)',
    attributeName: 'payment_wallet',
    helperTextClassName: 'text-red-600',
    placeholder: 'Payment Wallet',
    required: true,
    labelhelperText: '*',
    showBottomText:
      'This is the wallet address where you will receive Challenge rewards if you win',
  },
];

export const SUBMISSION_FORM_FIELDS = [
  // {
  //     type: 'text',
  //     label: 'Submission DataNFT Address',
  //     attributeName: 'proposalId',
  //     helperTextClassName: 'text-red-600',
  //     placeholder: "0x.....22f9",
  //     required: true,
  //     labelhelperText: "*",
  //     showInputBtn: false,
  //     inputBtnText: "Fetch Details",
  //     inputBtnDisabled: false
  // },
  {
    type: 'textArea',
    label: 'Proposal title',
    attributeName: 'title',
    helperTextClassName: 'text-red-600',
    placeholder: 'ETH Price Prediction using Random Forests',
    rows: 1,
    maxLength: 200,
    required: true,
    labelhelperText: '*',
  },
  {
    type: 'text',
    label: 'Your e-mail',
    attributeName: 'email',
    helperTextClassName: 'text-red-600',
    placeholder: 'email@example.com',
    readOnly: false,
    tooltip: 'Tooltip',
    enableTooltip: true,
    tooltipContent: 'E-mail is needed so that challenge host can reach out to you',
    required: true,
    labelhelperText: '*',
  },
  // {
  //   type: 'text',
  //   label: 'Asset URL',
  //   attributeName: 'assetUrl',
  //   helperTextClassName: 'text-red-600',
  //   placeholder: 'Asset URL (include https://)',
  //   required: true,
  //   labelhelperText: '*',
  // },
  {
    type: 'fileUpload',
    label: 'Asset File',
    attributeName: 'assetFile',
    helperTextClassName: 'text-red-600',
    placeholder: 'test',
    required: true,
    labelhelperText: '*',
  },
  {
    type: 'textEditor',
    textEditorList: [
      {
        uniqueID: 1,
        label: 'Proposal Details',
        attributeName: 'details',
        helperTextClassName: 'text-red-600',
        placeholder: 'Detailed description of what this submission is about...',
        required: true,
        labelhelperText: '*',
      },
    ],
  },
];

export const CHALLENGE_FORM_FIELDS = [
  {
    type: 'text',
    label: 'Title',
    attributeName: 'title',
    helperTextClassName: 'text-red-600',
    placeholder: 'Title',
    showBottomText: 'Letters ,Spaces and numbers are allowed',
    required: true,
    labelhelperText: '*',
  },
  {
    type: 'textArea',
    label: 'Summary',
    attributeName: 'summary',
    placeholder: 'Summary...',
    helperTextClassName: 'text-red-600',
    rows: 3,
    maxLength: 500,
    required: true,
    labelhelperText: '*',
  },
  {
    type: 'tags',
    label: 'Tags ',
    htmlFor: 'tags',
    attributeName: 'tags',
    placeholder: 'Select Tags',
    showBottomText: 'Letters ,Spaces and numbers are allowed',
  },
  {
    type: 'textEditor',
    textEditorList: [
      {
        uniqueID: 1,
        label: 'Description',
        attributeName: 'description',
        helperTextClassName: 'text-red-600',
        required: true,
        labelhelperText: '*',
        showSectionHeader: true,
      },
    ],
    draggable: true,
    addSectionBtn: true,
    addButtontext: 'Add Section',
    leftlableTitle: 'Section',
  },
  {
    type: 'textEditor',
    editorFor: 'dataset',
    textEditorList: [
      {
        uniqueID: 1,
        label: 'Dataset #1',
        attributeName: 'aboutDataset',
        helperTextClassName: 'text-red-600',
        required: false,
        labelhelperText: '*',
        showSectionHeader: true,
        extraInput: {
          fieldName: 'did',
          htmlFor: 'did',
          uniqueID: 1,
          value: '',
          inputPlaceholder:
            'Dataset DID (e.g.) did:op:012988cd0212ed01cbf141cbf141c7d88e or URL (e.g.) https://...',
          helperText: 'Dataset not found',
          verified: false,
          verifying: false,
          invalid: false,
        },
      },
    ],
    draggable: false,
    addSectionBtn: true,
    addButtontext: 'Add Dataset',
    leftlableTitle: 'Dataset',
    placeholder:
      'Help participants by mentioning - \n \n • What this dataset is about? \n • How can they use it? \n \n    And other details...',
  },
  {
    type: 'date',
    label: 'Deadline',
    htmlFor: 'deadline',
    attributeName: 'deadline',
    helperTextClassName: 'text-red-600 text-sm',
    required: true,
    labelhelperText: '*',
    showBottomText: 'DD.MM.YYYY HH:mm:ss (your local time)',
  },

  //FOR EVALUATION TEXT AREA
  {
    type: 'textEditor',
    needVerification: true,
    htmlFor: 'criterias',
    editorFor: 'criterias',
    attributeName: 'criterias',
    helperTextClassName: 'text-red-600',
    labelhelperText: '*',
    addSectionBtn: 'true',
    addButtontext: 'Add criteria',
    placeholder: 'Criteria Description...',
    leftlableTitle: 'Criteria',
    secondLabelTitle: 'Score',
    textEditorList: [
      {
        uniqueID: 1,
        label: '<Title>',
        attributeName: 'criteria',
        secondAttributeName: 'score', //for right side heading above texteditor
        secondLabel: '<Score>',
        helperTextClassName: 'text-red-600',
        required: true,
        labelhelperText: '*',
        showSectionHeader: true,
      },
    ],
  },
  {
    type: 'customInputField',
    label: 'Reviewers',
    htmlFor: 'reviewers',
    attributeName: 'Reviewers',
    helperTextClassName: 'text-red-600 text-sm',
    required: false,
    labelhelperText: '',
    subText:
      'Add reviewers who will review this challenge. Total Weight of all reviewers must be equal to 100.',
  },
  {
    type: 'currency',
    label: 'Reward',
    htmlFor: 'reward',
    required: true,
    labelhelperText: '*',
    amount: {
      label: '',
      htmlFor: 'rewardAmount',
      attributeName: 'rewardAmount',
      helperTextClassName: 'text-red-600',
      placeholder: 'Reward Amount',
    },
    currency: {
      label: '',
      htmlFor: 'rewardToken',
      attributeName: 'rewardToken',
      helperTextClassName: 'text-red-600',
      placeholder: 'Reward Token Address (e.g. 0x.....a1A1)',
    },
  },
];

export const DATASET_INITIAL_VALUE = {
  type: 'textEditor',
  editorFor: 'dataset',
  textEditorList: [
    {
      uniqueID: 1,
      label: 'Dataset #1',
      attributeName: 'aboutDataset',
      helperTextClassName: 'text-red-600',
      required: false,
      labelhelperText: '*',
      showSectionHeader: true,
      extraInput: {
        fieldName: 'did',
        htmlFor: 'did',
        uniqueID: 1,
        value: '',
        inputPlaceholder:
          'Dataset DID (e.g.) did:op:012988cd0212ed01cbf141cbf141c7d88e or dataset direct URL (e.g.) https://...',
        helperText: 'Dataset not found',
        verified: false,
        verifying: false,
        invalid: false,
      },
    },
  ],
  draggable: false,
  addSectionBtn: true,
  addButtontext: 'Add Dataset',
  leftlableTitle: 'Dataset',
  placeholder:
    'Help participants by mentioning - \n \n • What this dataset is about? \n • How can they use it? \n \n    And other details...',
};

export const ORGANIZATION_REQUIRED_FIELDS = ['name', 'email', 'bio', 'industry', 'website'];
export const IPFS_CHALLENGE_FIELDS = ['title', 'textEditor', 'tags', 'summary'];
export const WEB3_CHALLENGE_FIELDS = ['deadline', 'rewardAmount', 'rewardToken', 'reviewers'];
export const CHALLENGE_DEFAULT_EDITORS = ['criterias', 'description', 'evaluation'];
export const ORGANIZATION_FORM = 'organisation_form';
export const PROFILE_FORM = 'profile_form';
export const PROFILE_INVOICE_WARNING = `Let's get those invoicing details sorted in your profile, then we're good to go!`;
export const COUNTRY_ERROR_TEXT = `Due to restrictions related to OFAC-sanctioned countries, this functionality is currently unavailable in your region.`;
export const RETRY_WARNING_TEXT = `You have already attempted to submit. If you faced any issues and don't see your submission listed, please reach out to the admins via Discord.`;
