import { mdToDraftjs, draftjsToMd } from 'draftjs-md-converter';
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';
import { RawDraftContentState } from 'react-draft-wysiwyg';
import { CHALLENGE_COMPLETED, IPFS_CHALLENGE_FIELDS, WEB3_CHALLENGE_FIELDS } from '../Constants';
import { Challenge, FetchedChallenge, TextEditor } from '../../@types';
import { getRandom } from '.';
import dayjs from 'dayjs';
import { getUSDValueOfCoin } from './CommonHelpers';

// export const isValidHttpUrl = (string: string) => {
//   let url;
//   try {
//     url = new URL(string);
//     console.log(url);
//   } catch (_) {
//     return false;
//   }
//   return /^https:\/\/([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/.test(string);
//   /* To check whether the given string is valid URL or not */
// };

export const isValidHttpUrl = (string) => {
  try {
    const url = new URL(string);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (error) {
    return false;
  }
};

export const email_regex_pattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const exploreFormValues = (formFields: any) => {
  const formData = new FormData(formFields.target);
  const formProps = Object.fromEntries(formData);
  return formProps;
  /* For getting the form field values while submitting the form */
};

const myMdDict = {
  BOLD: '**',
  UNDERLINE: '~~',
  CODE: '```',
};

const appendingImage = ({ entityMap }: RawDraftContentState, MDString: string) => {
  const entityMapKeys = Object.keys(entityMap);
  let markdownString = MDString;
  entityMapKeys.forEach((key: string) => {
    const entity = entityMap[key];
    if (entity.type === 'IMAGE') {
      const { width, height, alignment = 'none' } = entity.data;
      markdownString = markdownString.replace('![]', `![${width},${height},${alignment},${key}]`);
    }
  });
  return markdownString;
};

// To convert the
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getMarkdown = (val: any) => {
  if (val) {
    const content = val?.getCurrentContent();
    const draftObj = convertToRaw(content);
    const MDString = draftjsToMd(draftObj, myMdDict);
    const imageFixedMDString = appendingImage(draftObj, MDString);
    return imageFixedMDString;
  }
};

const updateUnderline = (draftObj) => {
  draftObj.blocks = draftObj.blocks.map((block) => {
    if (block.inlineStyleRanges.length) {
      block.inlineStyleRanges = block.inlineStyleRanges.map((line) => {
        if (line.style === 'STRIKETHROUGH') {
          return { ...line, style: 'UNDERLINE' };
        }
        return line;
      });
    }
    return block;
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getMarkdownData = (val: any) => {
  if (val) {
    const content = val?.getCurrentContent();
    const markdownString = draftjsToMd(convertToRaw(content), myMdDict);
    return markdownString;
  }
  /* for converting the editor values in the markdown */
};
const myCustomStyles = {
  inlineStyles: {
    Code: {
      type: 'CODE',
      symbol: '```',
    },
    Delete: {
      type: 'STRIKETHROUGH',
      symbol: '~~',
    },
  },
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateImageProperty = (draftData: any) => {
  const entityMapKeys = ['data', 'mutability', 'type'];
  Object.keys(draftData?.entityMap)?.forEach((item) => {
    const {
      entityMap: {
        [item]: { type, data },
      },
    } = draftData;
    if (!entityMapKeys?.includes(item) && type === 'IMAGE') {
      const [width, height, alignment] = data.fileName.split(',');
      draftData['entityMap'][item]['data'] = { ...data, width, height, alignment, fileName: '' };
    }
  });
};

export const getEditorValues = (val: string = '') => {
  const draftData = mdToDraftjs(val, myCustomStyles);
  updateUnderline(draftData);
  updateImageProperty(draftData);
  const contentState = convertFromRaw(draftData);
  const newEditorState = EditorState.createWithContent(contentState);
  return newEditorState;
};

export const emptyVlidationFields = (formProps: string[]) => {
  let validation = {};
  Object.keys(formProps).forEach((key) => {
    if (formProps[key] === '') {
      validation = {
        ...validation,
        [key]: `${key.charAt(0).toUpperCase()}${key.slice(1)} cannot be empty`,
      };
    }
  });
  return validation;
  /* for validating the empty form fields */
};

export const emptyTextEditorValidationFields = (
  data: { innerText?: string; uniqueID?: string | number; label: string }[],
) => {
  let textEditorValidation = {};
  data.forEach((item: { innerText?: string; uniqueID?: string | number; label: string }) => {
    if (getMarkdownData(item?.innerText) === '' || !getMarkdownData(item?.innerText)) {
      textEditorValidation = {
        ...textEditorValidation,
        [item.uniqueID]: `${item.label} cannot be empty`,
      };
    }
  });
  return textEditorValidation;
  /* For validating text editor fields */
};

export const multiTextValidationFields = (data) => {
  let textEditorValidation = {};
  data.forEach((item: { title?: string; score?: number; description?: string }, index: number) => {
    const hasTitle = Boolean(item.title.trim());
    const hasScore = Boolean(item.score);
    const hasDescription = Boolean(item.description.trim());
    if (!hasDescription || !hasTitle || !hasScore) {
      textEditorValidation = {
        ...textEditorValidation,
        [index]: `${!hasDescription ? 'Details,' : ''} ${!hasTitle ? 'Title,' : ''} ${
          !hasScore ? 'Score,' : ''
        } cannot be empty`,
      };
    }
  });
  if (Object.keys(textEditorValidation).length > 0) {
    return { forMultiTextArea: { ...textEditorValidation } };
  } else {
    return {};
  }
};

function isNumber(value: string | number) {
  if (Number.isNaN(value)) {
    return false;
  }
  return typeof value === 'number' && isFinite(value);
}
/**FOR CRITERIAS ONLY */
export const criteriasValidationFields = (data) => {
  let textEditorValidation = {};
  data.forEach(
    (item: { innerText?: string; label: string; secondLabel?: string }, index: number) => {
      const hasTitle = Boolean(item.label.trim());
      const hasScore = Boolean(isNumber(parseInt(item.secondLabel)));
      const hasDescription = getMarkdown(item.innerText);
      if (!hasDescription || !hasTitle || !hasScore) {
        textEditorValidation = {
          ...textEditorValidation,
          [index]: `${!hasDescription ? 'Details,' : ''} ${!hasTitle ? 'Title,' : ''} ${
            !hasScore ? 'Score,' : ''
          } cannot be empty`,
        };
      }
    },
  );
  if (Object.keys(textEditorValidation).length > 0) {
    return { forCriterias: { ...textEditorValidation } };
  } else {
    return {};
  }
};

/**For datasets did only */

export const checkDIDFormat = (data: TextEditor[]): object => {
  let didValidation = {};
  data.forEach((val: TextEditor) => {
    if (
      val.extraInput.value &&
      !val.extraInput.value.startsWith('did:op:') &&
      !val.extraInput.value.startsWith('https://')
    ) {
      didValidation = {
        ...didValidation,
        [`did${val.uniqueID}`]: 'Please enter correct DID or valid dataset direct URL',
      };
    }
  });
  //IF FORMAT IS CORRECT AND WRONG DID IS SET
  if (Object.keys(didValidation).length < 1) {
    data.forEach((val: TextEditor) => {
      if (val.extraInput.value && val.extraInput.invalid) {
        didValidation = {
          ...didValidation,
          [`did${val.uniqueID}`]: 'Please enter correct DID or valid dataset direct URL',
        };
      }
    });
  }
  return didValidation;
};

export const getAPIMappedChallenge = async (challenge: Challenge, dirtyFields: string[] | null) => {
  const {
    rewardAmount,
    rewardToken,
    summary,
    tags: tagsArr = [],
    textFieldsValues = [],
    title,
    deadline,
    type = 1,
    status = 0,
    createdOn = 0,
    reviewers,
    criterias,
    datasets: dataset = [],
  } = challenge;
  const tags = tagsArr.map((tag) => tag.label);

  /**To calculate token value in USD */

  const tokenValueInUSD = await getUSDValueOfCoin(rewardToken);
  const rewardAmountUSD = tokenValueInUSD * parseInt(rewardAmount);
  /**To assign id to criterias */
  const criteriasWithIds = criterias?.map((criteria, index) => {
    return {
      description: criteria.description,
      score: criteria.score,
      title: criteria.title,
      id: index + 1,
    };
  });

  /**To have reviewers in lowercase */

  const reviewerInLower = lowercaseReviewers(reviewers);

  const sections = textFieldsValues?.map((field) => {
    return { name: field.fieldTitle, content: field.fieldValue, order: field.uniqueID };
  });
  const datasets = dataset?.map((field) => {
    return {
      order: field?.uniqueID,
      name: field?.fieldTitle,
      content: field?.fieldValue,
      did: field?.did,
    };
  });
  let IPFSChallengeData = null,
    Web3ChallengeData = null;
  if (dirtyFields !== null) {
    if (dirtyFields.filter((field) => IPFS_CHALLENGE_FIELDS.includes(field)).length) {
      IPFSChallengeData = {
        summary,
        title,
        tags,
        sections,
        criterias: criteriasWithIds,
        datasets,
        rewardAmountUSD,
      };
    }
    if (dirtyFields.filter((field) => WEB3_CHALLENGE_FIELDS.includes(field)).length) {
      Web3ChallengeData = {
        rewardAmount: Number(rewardAmount),
        rewardToken,
        deadline,
        type,
        status,
        createdOn,
        reviewers: reviewerInLower,
      };
    }
  } else {
    IPFSChallengeData = {
      summary,
      title,
      tags,
      sections,
      criterias: criteriasWithIds,
      datasets,
      rewardAmountUSD,
    };
    Web3ChallengeData = {
      rewardAmount: Number(rewardAmount),
      rewardToken,
      deadline: deadline,
      type,
      status,
      createdOn,
      reviewers: reviewerInLower,
    };
  }
  //Added these so that when updating , if IPFS or Web3challengeData doesnt changed it doesnt get null
  if (IPFSChallengeData === null) {
    IPFSChallengeData = {
      summary,
      title,
      tags,
      sections,
      criterias: criteriasWithIds,
      datasets,
      rewardAmountUSD,
    };
  }
  if (Web3ChallengeData === null) {
    Web3ChallengeData = {
      rewardAmount: Number(rewardAmount),
      rewardToken,
      deadline: deadline,
      type,
      status,
      createdOn,
      reviewers: reviewerInLower,
    };
  }
  return { IPFSChallengeData, Web3ChallengeData };
};

export const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return '';
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const isChallengeExpired = (status, deadline) => {
  if (dayjs.unix(deadline).isBefore(dayjs()) && status) return 0;
  return 1;
};

export const getFormMappedChallenge = (fetchedChallenge: FetchedChallenge) => {
  const {
    title = '',
    sections = [],
    tags: tagsArr = [],
    summary = '',
    deadline = '',
    rewardAmount = '',
    rewardToken = '',
    orgProfileAddress = '',
    challengeId = 0,
    type = 1,
    status = 0,
    createdOn = 0,
    criterias,
    reviewers,
    datasets = [],
  } = fetchedChallenge;
  const tags = tagsArr?.map((tag) => ({
    label: tag,
    badgeId: getRandom(),
  }));

  let textFieldsValues = [];
  let datasetFieldValues = [];
  let reviewersArr = [];
  let criteriaArr = [];
  if (sections?.length > 0) {
    textFieldsValues = [...sections]
      .sort((sec) => sec.order)
      ?.map((sec, inx) => {
        return {
          fieldName: camelize(sec.name),
          fieldValue: sec.content,
          fieldTitle: sec.name,
          uniqueID: sec.order ?? inx + 1,
        };
      });
  }

  if (datasets?.length > 0) {
    datasetFieldValues = [...datasets.filter((dataset) => dataset?.did !== '')]
      ?.sort((sec) => sec.order)
      ?.map((sec, inx) => {
        return {
          fieldName: camelize(sec.name),
          fieldValue: sec.content,
          fieldTitle: sec.name,
          uniqueID: sec.order ?? inx + 1,
          did: sec.did,
        };
      });
  }
  /**delteting extra field added by GQL in cache during editing and assigning i.d to criteria*/
  if (reviewers?.length > 0) {
    reviewersArr = reviewers?.map((review) => {
      return {
        reviewer: review?.reviewer,
        weight: review?.weight,
        assignedCriterias: review?.assignedCriterias ?? [],
      };
    });
  }

  if (criterias?.length > 0) {
    criteriaArr = criterias?.map((criteria, index) => {
      return {
        description: criteria.description,
        score: criteria.score,
        title: criteria.title,
        id: index + 1,
      };
    });
  }

  return {
    challengeId,
    challengeResolved: false,
    deadline,
    profileId: orgProfileAddress,
    rewardAmount,
    rewardToken,
    submissions: [],
    summary,
    tags,
    textFieldsValues,
    datasetFieldValues,
    title,
    type,
    status,
    createdOn,
    criterias: criteriaArr,
    reviewers: reviewersArr,
  };
};

/**This is for mark challenge complete as prefetched data have GQL fields */

export const generateUpdatedChallengeData = (originalData) => {
  const curChallengeData = structuredClone(originalData);

  // Remove '__typename' properties
  delete curChallengeData['__typename'];
  curChallengeData?.criterias?.forEach((criteria, index) => {
    delete curChallengeData['criterias'][index]['__typename'];
  });
  curChallengeData?.reviewers?.forEach((criteria, index) => {
    delete curChallengeData['reviewers'][index]['__typename'];
  });
  curChallengeData?.datasets?.forEach((criteria, index) => {
    delete curChallengeData['datasets'][index]['__typename'];
  });
  let datasetFieldValues = [];

  if (curChallengeData?.datasets?.length > 0) {
    datasetFieldValues = [...curChallengeData.datasets]
      .sort((sec) => sec.order)
      ?.map((sec, inx) => {
        return {
          fieldName: camelize(sec.name),
          fieldValue: sec.content,
          fieldTitle: sec.name,
          uniqueID: sec.order ?? inx + 1,
          did: sec.did,
        };
      });
  }
  return {
    ...curChallengeData,
    status: CHALLENGE_COMPLETED,
    profileId: curChallengeData.org,
    challengeId: parseInt(curChallengeData.id),
    tags: curChallengeData.tags?.map((tag) => ({
      label: tag,
      badgeId: getRandom(),
    })),
    datasets: datasetFieldValues,
    textFieldsValues: (curChallengeData?.sections || [])
      ?.sort((sec) => sec?.order)
      ?.map((sec, inx) => {
        return {
          fieldName: camelize(sec.name),
          fieldValue: sec.content,
          fieldTitle: sec.name,
          uniqueID: sec.order ?? inx + 1,
        };
      }),
    rewardAmount: curChallengeData.reward_amount,
    rewardToken: curChallengeData.reward_token,
  };
};

/**To convert reviewers in lower case */

export function lowercaseReviewers(inputArray) {
  const result = [];
  const tempArr = structuredClone(inputArray);
  for (let i = 0; i < tempArr.length; i++) {
    const item = tempArr[i];
    const lowercaseReviewer = item.reviewer.toLowerCase(); // Convert to lowercase
    const newItem = {
      reviewer: lowercaseReviewer,
      weight: item?.weight, // Keep the rest of the properties unchanged
      assignedCriterias: item?.assignedCriterias ?? [],
    };
    result.push(newItem);
  }

  return result;
}
