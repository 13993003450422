import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup, Spinner } from 'flowbite-react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { AppDispatch, Profile, RootState, sidebarProfileDropDownInterface } from '../../@types';
import {
  ORG_CHALLENGE_LIST_PATH,
  ORG_PROFILE_CREATE_PATH,
  USER_SUBMISSIONS_LIST_PATH,
} from '../../Utils/Constants';
import CommonAvatar from '../../Components/CommonAvatar';
import { updateCurrentProfileAction, updateSelectedProfileAction } from '../../Store/Actions';

export const SidebarProfileDropdown = ({
  ProfileUserData,
  showListselected,
  setEnableProfileDropdown,
}: sidebarProfileDropDownInterface) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const defaultProfile = useSelector((state: RootState) => state.profile.defaultProfile);
  const currentProfile = useSelector((state: RootState) => state.profile.currentProfile);
  return (
    <ListGroup className='!border-0 dark:!bg-primary-800'>
      {ProfileUserData.map((profile: Profile, index: number) => {
        const { profileImage, profileId, name, isOrganization } = profile;
        return (
          <div className='mt-1 profile_section' key={index}>
            <ListGroup.Item
              onClick={() => {
                dispatch(updateSelectedProfileAction({ profileId, isOrg: isOrganization }));
                dispatch(updateCurrentProfileAction(profile));
                navigate(!isOrganization ? USER_SUBMISSIONS_LIST_PATH : ORG_CHALLENGE_LIST_PATH);
                setEnableProfileDropdown(false);
              }}
            >
              <div
                className={`flex w-full rounded-lg p-2 hover:dark:bg-primary-600 ${
                  profile.profileId == currentProfile.profileId
                    ? 'bg-primary-50 dark:bg-primary-600'
                    : ''
                }`}
              >
                <CommonAvatar img={profileImage} size='sm' />
                <div className='pl-3 w-full text-start'>
                  <div className='truncate pr-3'>{name}</div>
                  <div className='text-start text-sm text-gray-500 dark:text-headingColor'>
                    {isOrganization ? 'Organization Profile' : 'User Profile'}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
          </div>
        );
      })}
      {/* doing -1 here because profileUserdata have user info too along with orgs */}
      {defaultProfile.organizations.length < ProfileUserData.length - 1 && (
        <div className='flex justify-center'>
          <Spinner size='lg' />
        </div>
      )}
      {showListselected.login && (
        <>
          <hr className=' border-gray-200 dark:border-primary-700 mt-2 mb-2' />
          <div className='profile_section'>
            <ListGroup.Item
              active={false}
              onClick={() => {
                navigate(ORG_PROFILE_CREATE_PATH);
                setEnableProfileDropdown(false);
              }}
            >
              <div className='flex w-full items-center py-2 px-2 hover:dark:bg-primary-600 rounded-lg'>
                <div>
                  <AiOutlinePlusCircle size={25} color='primary' />
                </div>
                <div className='pl-3'>Create Organization</div>
              </div>
            </ListGroup.Item>
          </div>
        </>
      )}
    </ListGroup>
  );
};
