import { useEffect } from 'react';
import { FETCH_ALL_SUBMISSIONS_FOR_USER, SUBMISSION_DETAILS } from '../../graphql/submissions';
import { useLazyQuery, useQuery } from '@apollo/client';
import { FETCH_USER_PROFILE_DETAILS } from '../../graphql/users';

export const useSubmission = (proposalId, profileId, challengeId) => {
  let userParticiapted = false;
  const { loading: l1, data: d1 } = useQuery(SUBMISSION_DETAILS, {
    variables: { submissionId: Number(proposalId) },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    skip: !proposalId,
  });
  const selectedProposal = d1?.fetchSubmissionDetails ?? {};

  const {
    loading: l3,
    error: error,
    data,
  } = useQuery(FETCH_ALL_SUBMISSIONS_FOR_USER, {
    variables: { profileAddress: profileId },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });

  if (challengeId) {
    userParticiapted = Boolean(
      data?.fetchAllSubmissionsForGivenUser?.filter((sub) => sub?.challengeId == challengeId)
        .length,
    );
  }

  const [getUser, { loading: l2, data: creatorInfo }] = useLazyQuery(FETCH_USER_PROFILE_DETAILS);

  const fetchUserInfoHandler = async () => {
    await getUser({ variables: { id: selectedProposal?.creator } });
  };

  useEffect(() => {
    if (selectedProposal?.creator) {
      fetchUserInfoHandler();
    }
  }, [selectedProposal]);

  return {
    userParticiapted,
    selectedProposal,
    creatorInfo,
    loading: l1 || l2 || l3,
    error,
  };
};
