import { createSlice } from '@reduxjs/toolkit';
import { initialProfile } from './ProfileSlice';

const memberIntitalState = {
  membersList: [],
};

// Localstorage persistense - Not tested well.
let initialState = memberIntitalState;
const presistedState = localStorage.getItem('persistedstate');
if (presistedState) {
  initialState = JSON.parse(presistedState)?.members ?? memberIntitalState;
}

const memberSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    memberReset: () => memberIntitalState,
    addMember: (state, { payload }) => {
      state.membersList.push(payload);
    },
    updateMember: (state, { payload }) => {
      const inx = state.membersList.findIndex((data) => data.profileId === payload.profileId);
      if (inx === -1) {
        state.membersList.push(payload);
      } else {
        state.membersList[inx] = { ...initialProfile, ...state.membersList[inx], ...payload };
      }
    },
    addProposalToMember: (state, { payload }) => {
      const inx = state.membersList.findIndex((data) => data.profileId === payload.profileId);
      state.membersList[inx].submissions.push(payload.proposalId);
    },
    removeProposalFromMember: (state, { payload }) => {
      const inx = state.membersList.findIndex((data) => data.profileId === payload.profileId);
      state.membersList[inx].submissions = state.membersList[inx].submissions.filter(
        (proposalId: string) => proposalId !== payload.proposalId,
      );
    },
    addOrgToMember: (state, { payload }) => {
      const inx = state.membersList.findIndex((data) => data.profileId === payload.userProfileId);
      state.membersList[inx].organizations.push(payload.orgProfileId);
    },
    removeOrgFromMember: (state, { payload }) => {
      const inx = state.membersList.findIndex(
        (data) => data.adminProfileId === payload.adminProfileId,
      );
      state.membersList[inx].organizations = state.membersList[inx].organizations.filter(
        (orgId: string) => orgId !== payload.profileId,
      );
    },
    addOrgsToMember: (state, { payload }) => {
      const inx = state.membersList.findIndex((data) => data.profileId === payload.userProfileId);
      state.membersList[inx].organizations = payload.userOrgs;
    },
  },
});

export default memberSlice.reducer;
export const {
  memberReset,
  updateMember,
  addMember,
  addProposalToMember,
  removeProposalFromMember,
  removeOrgFromMember,
  addOrgToMember,
  addOrgsToMember,
} = memberSlice.actions;
