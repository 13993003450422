import { Spinner } from 'flowbite-react';
import { BsCheckCircleFill } from 'react-icons/bs';
import { HiOutlineClock } from 'react-icons/hi';
import { MdOutlineHelpOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { RootState, TxnStep } from '../../@types';
import { STEP_COMPLETED, STEP_NOT_STARTED, STEP_PENDING } from '../../Utils/Constants';

const TxnSteps = () => {
  const currentSteps = useSelector((state: RootState) => state.utils.currentSteps);
  const renderStatusIcon = (step: TxnStep) => {
    switch (step.status) {
      case STEP_NOT_STARTED:
        return step.stepId + '. ';
      case STEP_PENDING:
        return <HiOutlineClock className='text-primary-500 text-xl self-center' />;
      case STEP_COMPLETED:
        return <BsCheckCircleFill className='text-primary-500 text-base self-center' />;
      default:
        return step.stepId + '.';
    }
  };
  return (
    <div className='dark:text-white'>
      <div className='text-sm text-gray-500 dark:text-gray-300'>
        There are {currentSteps?.length ?? 0} steps for this process and you might have to sign
        transactions on the steps with the 🔑
        <div className='space-y-3 my-3'>
          {currentSteps.map((step, index) => (
            <div key={index} className='p-4 rounded-lg bg-gray-100 text-black dark:bg-gray-700'>
              <div className='flex items-center gap-2 justify-between'>
                <div className='flex gap-2 font-bold dark:text-white'>
                  {renderStatusIcon(step)}
                  {step.text}
                </div>
                {step.status === STEP_PENDING && <Spinner />}
              </div>
            </div>
          ))}
        </div>
        <div className='text-gray-400 text-sm justify-center dark:text-gray-400 flex gap-1 cursor-pointer hover:underline'>
          <MdOutlineHelpOutline className='text-lg self-center' />
          <div className='self-center'>Why do I need to sign transactions?</div>
        </div>
      </div>
    </div>
  );
};

export default TxnSteps;
