import axios from 'axios';
import { getTokenInfo } from '.';
import { allCountries } from '../Constants/allcountries';

export const getCookie = (cookieName: string) => {
  const cookieValue = document.cookie.split('; ').find((row) => row.startsWith(cookieName));
  return cookieValue ? JSON.parse(cookieValue.split('=')[1]) : null;
};
export const setCookie = (
  cookieName: string,
  value: string[] | null,
  expiry: number = 60 * 60 * 24 * 365,
) => {
  document.cookie = cookieName + '=' + JSON.stringify(value) + '; path=/; max-age=' + expiry; // 12 months
};

export function getLevelColor(level: number) {
  return `text-levelColor-${level + 1}`;
}

export function checkIfStringExists(...args) {
  // Convert the "arguments" object to a real array
  const parameterArray = Array.from(args);

  console.log(parameterArray);
}

export function removeSubstring(inputString: string, replaceWith: string | null) {
  // Use the replace method to remove '/u/' from the inputString
  const resultString = inputString.replace('/g', replaceWith);

  return resultString;
}

export async function getUSDValueOfCoin(rewardToken: string) {
  try {
    const tokenInfo = getTokenInfo(rewardToken);

    if (tokenInfo.symbol == 'USDC' || tokenInfo.symbol == 'USDT' || tokenInfo.symbol == 'DAI') {
      return 1;
    }

    const res = await axios(
      `https://api.coingecko.com/api/v3/simple/token_price/polygon-pos?contract_addresses=${rewardToken}&vs_currencies=usd`,
    );
    return res.data[rewardToken.toLowerCase()].usd;
  } catch (er) {
    return 1;
  }
}

//To concert any object to CSV such that keys become heading, considering all keys are same in array of object
export function convertArrayOfObjectsToCSV(array) {
  if (array.length === 0) {
    console.warn('Array is empty. No CSV data generated.');
    return null;
  }

  const headers = Object.keys(array[0]);

  const csvContent = `${headers.join(',')}\n${array
    .map((obj) => headers.map((header) => obj[header]).join(','))
    .join('\n')}`;

  return csvContent;
}
//To download any file/image from data
export function downloadCSV(csvData, fileName) {
  const blob = new Blob([csvData], { type: 'text/csv' });

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName || 'download.csv';

  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

//To start download any url instantly
export function startDownloadFromLink(url, fileName) {
  console.log('THIS IS FILENAME', fileName);
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  // a.download = fileName || 'download';

  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

export const fetchUserCountry = async () => {
  try {
    // Make a request to the ipinfo.io API
    const response = await axios.get(
      `https://ipinfo.io?token=${process.env.REACT_APP_IPINFO_TOKEN}`,
    );
    const data = response.data;

    // Extract the country from the API response
    const country =
      allCountries[allCountries.findIndex((country) => country.code === data.country)].name;

    return country;
  } catch (error) {
    console.error('Error fetching IP information', error);
  }
};

/**Returns string size in KB */
export function getJsonSize(jsonStr) {
  const sizeInBytes = new Blob([jsonStr]).size;
  const sizeInKB = sizeInBytes / 1024;
  return sizeInKB.toFixed(2); // Round to two decimal places
}

/**
 * Formats a number string to two decimal places without rounding.
 * If the input value has more than two decimal places, it truncates the extra digits.
 * If the input value has no decimal point, it returns the original value.
 *
 * @param {string} value - The input number as a string.
 * @returns {number} - The formatted number with up to two decimal places as a float.
 */
export function formatToTwoDecimals(value) {
  if (!value) {
    return 0;
  }
  if (!value.toString()?.includes('.')) return parseFloat(value); // If no decimal point, return the original value as a float

  const [integerPart, decimalPart] = value.toString().split('.');

  const formattedValue = `${integerPart}.${decimalPart.slice(0, 2)}`;

  return parseFloat(formattedValue);
}
